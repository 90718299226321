import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card } from "@/components/ui/card";
import { MessageSquare } from "lucide-react";

interface ChatSetting {
  id: number;
  key: string;
  value: string;
  category: string;
}

export default function ChatSettings() {
  const { toast } = useToast();
  const [settings, setSettings] = useState<ChatSetting[]>([]);
  const [selectedModel, setSelectedModel] = useState("gpt-4");
  const [temperature, setTemperature] = useState("0.7");
  const [maxTokens, setMaxTokens] = useState("2000");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await fetch("/api/settings/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: selectedModel,
          temperature: parseFloat(temperature),
          max_tokens: parseInt(maxTokens, 10),
        }),
      });

      if (!response.ok) throw new Error("Failed to save settings");

      toast({
        title: "Settings saved",
        description: "Chat settings have been updated successfully",
      });
    } catch (error) {
      toast({
        title: "Save failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <MessageSquare className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-medium">AI Model Configuration</h3>
        </div>

        <div className="space-y-4">
          {/* Model Selection */}
          <div className="space-y-2">
            <Label htmlFor="model">AI Model</Label>
            <Select value={selectedModel} onValueChange={setSelectedModel}>
              <SelectTrigger>
                <SelectValue placeholder="Select AI model" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="gpt-4">GPT-4</SelectItem>
                <SelectItem value="gpt-3.5-turbo">GPT-3.5 Turbo</SelectItem>
                <SelectItem value="claude-2">Claude 2</SelectItem>
                <SelectItem value="pplx-7b-chat">Perplexity 7B Chat</SelectItem>
                <SelectItem value="pplx-70b-chat">
                  Perplexity 70B Chat
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Temperature */}
          <div className="space-y-2">
            <Label htmlFor="temperature">Temperature</Label>
            <div className="flex items-center gap-2">
              <Input
                id="temperature"
                type="number"
                min="0"
                max="2"
                step="0.1"
                value={temperature}
                onChange={(e) => setTemperature(e.target.value)}
                className="w-24"
              />
              <span className="text-sm text-gray-500">
                (0 = deterministic, 2 = creative)
              </span>
            </div>
          </div>

          {/* Max Tokens */}
          <div className="space-y-2">
            <Label htmlFor="max-tokens">Max Tokens</Label>
            <div className="flex items-center gap-2">
              <Input
                id="max-tokens"
                type="number"
                min="100"
                max="4000"
                step="100"
                value={maxTokens}
                onChange={(e) => setMaxTokens(e.target.value)}
                className="w-24"
              />
              <span className="text-sm text-gray-500">(100 - 4000 tokens)</span>
            </div>
          </div>

          <Button onClick={handleSave} disabled={isSaving} className="mt-4">
            {isSaving ? "Saving..." : "Save Settings"}
          </Button>
        </div>
      </Card>

      <Card className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <MessageSquare className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-medium">Chat History</h3>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">
              Chat history is stored for 30 days
            </span>
            <Button variant="outline" size="sm">
              Clear History
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
