import { useState, useEffect } from "react";
import { useLocation } from "wouter";
import { checkAuthStatus } from "@/lib/auth";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import AIModelSettings from "@/components/settings/AIModelSettings";
import ConvertSettings from "@/components/settings/ConvertSettings";
import HSLookupSettings from "@/components/settings/HSLookupSettings";
import FCLRateSettings from "@/components/settings/FCLRateSettings";
import ChatSettings from "@/components/settings/ChatSettings";
import DocumentCheckSettings from "@/components/settings/DocumentCheckSettings";

export default function Settings() {
  const [, navigate] = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const verifyAuth = async () => {
      const isAuth = await checkAuthStatus();
      setIsAuthenticated(isAuth);

      if (!isAuth) {
        navigate("/login");
      }
    };

    verifyAuth();
  }, [navigate]);

  return (
    <div className="container mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Settings</CardTitle>
          <CardDescription>Configure your application settings</CardDescription>
        </CardHeader>
        <CardContent>
          <Accordion type="single" collapsible className="w-full">
            {/* AI Model Settings */}
            <AccordionItem value="ai-model-settings">
              <AccordionTrigger>AI Model Settings</AccordionTrigger>
              <AccordionContent>
                <AIModelSettings />
              </AccordionContent>
            </AccordionItem>

            {/* Convert to Excel Settings */}
            <AccordionItem value="convert-settings">
              <AccordionTrigger>Convert to Excel Settings</AccordionTrigger>
              <AccordionContent>
                <ConvertSettings />
              </AccordionContent>
            </AccordionItem>

            {/* HS Lookup Settings */}
            <AccordionItem value="hs-lookup-settings">
              <AccordionTrigger>HS Lookup Settings</AccordionTrigger>
              <AccordionContent>
                <HSLookupSettings />
              </AccordionContent>
            </AccordionItem>

            {/* FCL Rate Settings */}
            <AccordionItem value="fcl-rate-settings">
              <AccordionTrigger>FCL Rate Settings</AccordionTrigger>
              <AccordionContent>
                <FCLRateSettings />
              </AccordionContent>
            </AccordionItem>

            {/* Chat Settings */}
            <AccordionItem value="chat-settings">
              <AccordionTrigger>Chat Settings</AccordionTrigger>
              <AccordionContent>
                <ChatSettings />
              </AccordionContent>
            </AccordionItem>

            {/* Document Check Settings */}
            <AccordionItem value="document-check-settings">
              <AccordionTrigger>Document Check Settings</AccordionTrigger>
              <AccordionContent>
                <DocumentCheckSettings />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </CardContent>
      </Card>
    </div>
  );
}
