import { Navigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { hasPageAccess } from '@/lib/permissions';

interface RoleGuardProps {
  children: React.ReactNode;
  page: keyof typeof PAGE_PERMISSIONS;
}

export default function RoleGuard({ children, page }: RoleGuardProps) {
  const { user } = useAuth();

  if (!hasPageAccess(user, page)) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}
