import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { getApiUrl } from "./config";
import { getAuthHeaders } from "./auth";

// Types
interface Template {
  id: number;
  name: string;
  filename: string;
  created_at: string;
}

interface TemplatesResponse {
  success: boolean;
  templates: Template[];
}

interface FCLRate {
  id: number;
  origin: string;
  destination: string;
  carrier: string;
  transit_time: string;
  cost: number;
  rebate: number;
  valid_until: string;
}

interface FCLRatesResponse {
  success: boolean;
  rates: FCLRate[];
}

interface ConversionJob {
  id: number;
  status: "pending" | "processing" | "completed" | "failed";
  progress: number;
  error?: string;
  originalFilename: string;
  templateName: string;
  createdAt: string;
  completedAt?: string;
  downloadUrl?: string;
}

interface ChatMessage {
  role: "user" | "assistant";
  content: string;
  timestamp: Date;
}

interface DocumentContext {
  id: string;
  filename: string;
  timestamp: Date;
}

interface ChatResponse {
  message: string;
  success: boolean;
  error?: string;
}

interface DocumentContextResponse {
  success: boolean;
  context: DocumentContext;
  error?: string;
}

// API response types
export interface ApiResponse<T = any> {
  success: boolean;
  error?: string;
  details?: any;
  data?: T;
}

// Feature model settings types
export interface FeatureModelSetting {
  id: number;
  feature_name: string;
  model_id: number;
  provider: string;
  model_name: string;
  friendly_name: string;
}

// AI Model types
export interface AIModel {
  id: number;
  provider: string;
  model_name: string;
  friendly_name: string;
  is_custom: boolean;
  enabled: boolean;
  created_at: string;
}

export interface AIModelsResponse {
  success: boolean;
  models: AIModel[];
}

export const apiClient = {
  get: async <T>(endpoint: string): Promise<T> => {
    const response = await fetch(`${getApiUrl()}/${endpoint}`, {
      headers: {
        ...getAuthHeaders(),
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    return response.json();
  },

  post: async <T>(endpoint: string, data?: any): Promise<T> => {
    const response = await fetch(`${getApiUrl()}/${endpoint}`, {
      method: "POST",
      headers: {
        ...getAuthHeaders(),
        "Content-Type": "application/json",
      },
      body: data ? JSON.stringify(data) : undefined,
    });

    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    return response.json();
  },

  // Add other methods as needed
};

// Feature model settings API functions
export const getFeatureModelSettings = () =>
  apiClient.get<{ success: boolean; settings: FeatureModelSetting[] }>(
    "settings/feature-models"
  );

export const updateFeatureModelSetting = (feature: string, model_id: number) =>
  apiClient.post<{ success: boolean }>(`settings/feature-models/${feature}`, {
    model_id,
  });

// AI Model API functions
export const getAIModels = () =>
  apiClient.get<AIModelsResponse>("settings/ai-models");

export const addAIModel = (model: {
  provider: string;
  friendly_name: string;
  model_name: string;
}) =>
  apiClient.post<{ success: boolean; model: AIModel }>(
    "settings/ai-models",
    model
  );

export const updateAIModel = (id: number, enabled: boolean) =>
  apiClient.post<{ success: boolean }>(`settings/ai-models/${id}`, { enabled });

export const deleteAIModel = (id: number) =>
  apiClient.delete(`settings/ai-models/${id}`);

// Template Management
export const useTemplates = () => {
  return useQuery<TemplatesResponse>({
    queryKey: ["templates"],
    queryFn: async () => {
      const response = await fetch("/api/templates");
      if (!response.ok) {
        throw new Error("Failed to fetch templates");
      }
      return response.json();
    },
  });
};

export const useUploadTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ file, name }: { file: File; name: string }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);

      const response = await fetch("/api/templates/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload template");
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["templates"] });
    },
  });
};

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (templateId: number) => {
      const response = await fetch(`/api/templates/${templateId}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete template");
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["templates"] });
    },
  });
};

// Conversion Management
export function useUploadForConversion() {
  return useMutation({
    mutationFn: async ({
      file,
      templateId,
    }: {
      file: File;
      templateId: string;
    }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("templateId", templateId);

      return apiClient.post<{ success: boolean; jobId: number }>(
        "convert/upload",
        formData
      );
    },
  });
}

export function useConversionStatus(jobId: string | null) {
  return useQuery({
    queryKey: ["conversion-status", jobId],
    queryFn: () =>
      apiClient.get<{ success: boolean; job: ConversionJob }>(
        `convert/status/${jobId}`
      ),
    enabled: !!jobId,
    refetchInterval: (query) =>
      query.state.data?.job?.status === "completed" ||
      query.state.data?.job?.status === "failed"
        ? false
        : 1000,
  });
}

export function useRecentConversions(limit = 10, offset = 0) {
  return useQuery({
    queryKey: ["recent-conversions", limit, offset],
    queryFn: () =>
      apiClient.get<{
        success: boolean;
        jobs: ConversionJob[];
        total: number;
        limit: number;
        offset: number;
      }>(`convert/list?limit=${limit}&offset=${offset}`),
  });
}

// FCL Rate Management
export const useFCLRates = () => {
  return useQuery<FCLRatesResponse>({
    queryKey: ["fcl-rates"],
    queryFn: async () => {
      const response = await fetch("/api/fcl-rates");
      if (!response.ok) {
        throw new Error("Failed to fetch FCL rates");
      }
      return response.json();
    },
  });
};

export const useUploadFCLRate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch("/api/fcl-rates/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload FCL rate");
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fcl-rates"] });
    },
  });
};

export const useDeleteFCLRate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rateId: number) => {
      const response = await fetch(`/api/fcl-rates/${rateId}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete FCL rate");
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fcl-rates"] });
    },
  });
};

export function useChatWithContext(documentContext: DocumentContext | null) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const sendMessage = async (content: string) => {
    if (!content.trim()) return;

    const userMessage: ChatMessage = {
      role: "user",
      content,
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setIsLoading(true);

    try {
      const response = await fetch(getApiUrl("chat"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: content,
          documentContext: documentContext
            ? {
                id: documentContext.id,
                filename: documentContext.filename,
              }
            : null,
          history: messages,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      const data = (await response.json()) as ChatResponse;

      if (!data.success) {
        throw new Error(data.error || "Failed to get response");
      }

      const assistantMessage: ChatMessage = {
        role: "assistant",
        content: data.message,
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, assistantMessage]);
    } catch (error) {
      console.error("Chat error:", error);
      // Add error message to chat
      const errorMessage: ChatMessage = {
        role: "assistant",
        content: "Sorry, I encountered an error processing your message.",
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    messages,
    sendMessage,
    isLoading,
  };
}

export function useDocumentContext() {
  const [documentContext, setDocumentContext] =
    useState<DocumentContext | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadDocument = async (file: File) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(getApiUrl("chat/context"), {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload document");
      }

      const data = (await response.json()) as DocumentContextResponse;

      if (!data.success) {
        throw new Error(data.error || "Failed to upload document");
      }

      setDocumentContext({
        ...data.context,
        timestamp: new Date(data.context.timestamp),
      });

      return true;
    } catch (error) {
      console.error("Document upload error:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const clearContext = () => {
    setDocumentContext(null);
  };

  return {
    documentContext,
    uploadDocument,
    clearContext,
    isLoading,
  };
}
