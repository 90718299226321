import { getApiUrl } from "./config";

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

interface SessionData {
  user?: {
    id: string;
    username: string;
    role: string;
  };
  tokens?: {
    accessToken: string;
    refreshToken: string;
  };
  expiresAt: number; // Timestamp when the session expires
  lastChecked: number; // Timestamp of last auth check
}

// Constants
const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds
const SESSION_KEY = "freight_forwarding_session";
const TOKEN_EXPIRY = 60 * 60 * 1000; // 1 hour in milliseconds

// Helper to safely parse stored session
const parseStoredSession = (): SessionData | null => {
  try {
    const stored = localStorage.getItem(SESSION_KEY);
    if (!stored) return null;
    return JSON.parse(stored);
  } catch (error) {
    console.error("Failed to parse stored session:", error);
    return null;
  }
};

// Store session data
const storeSession = (sessionData: SessionData) => {
  localStorage.setItem(SESSION_KEY, JSON.stringify(sessionData));
};

// Clear session data
const clearSession = () => {
  localStorage.removeItem(SESSION_KEY);
  localStorage.removeItem("token"); // Remove old token for backwards compatibility
};

// Check if token needs refresh
const needsRefresh = (expiresAt: number): boolean => {
  const now = Date.now();
  return expiresAt - now < TOKEN_REFRESH_THRESHOLD;
};

// Refresh token
const refreshTokens = async (refreshToken: string): Promise<AuthTokens> => {
  const response = await fetch(`${getApiUrl()}/auth/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refreshToken }),
  });

  if (!response.ok) {
    throw new Error("Failed to refresh token");
  }

  const data = await response.json();
  return {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
  };
};

// Main auth check function with refresh logic
export const checkAuthStatus = async () => {
  try {
    const session = parseStoredSession();
    if (!session?.tokens?.accessToken) {
      console.log("No session found");
      return false;
    }

    // Check if we've verified auth recently (within last minute)
    const now = Date.now();
    if (session.lastChecked && now - session.lastChecked < 60 * 1000) {
      console.log("Using cached auth check");
      return true;
    }

    // Verify current token
    const response = await fetch(`${getApiUrl()}/auth/check`, {
      headers: {
        Authorization: `Bearer ${session.tokens.accessToken}`,
      },
    });

    if (!response.ok) {
      console.log("Auth check failed:", response.status);
      clearSession();
      return false;
    }

    const data = await response.json();
    if (!data.success) {
      console.log("Auth check returned unsuccessful:", data.error);
      clearSession();
      return false;
    }

    // Update session with new expiry
    const updatedSession: SessionData = {
      ...session,
      user: data.user,
      expiresAt: new Date(data.session.expiresAt).getTime(),
      lastChecked: now,
    };

    storeSession(updatedSession);
    return true;
  } catch (error) {
    console.error("Auth check failed:", error);
    clearSession();
    return false;
  }
};

// Login function
export const login = async (
  username: string,
  password: string
): Promise<SessionData> => {
  const response = await fetch(`${getApiUrl()}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });

  if (!response.ok) {
    throw new Error("Login failed");
  }

  const data = await response.json();
  console.log("Login response:", data);

  // Store both session data and token
  const sessionData: SessionData = {
    user: data.user,
    tokens: {
      accessToken: data.token,
      refreshToken: "", // We don't use refresh tokens with KV sessions
    },
    expiresAt: new Date(data.session.expiresAt).getTime(),
    lastChecked: Date.now(),
  };

  // Store session data and token
  storeSession(sessionData);
  localStorage.setItem("token", data.token);
  console.log("Stored session data:", sessionData);

  return sessionData;
};

// Logout function
export const logout = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const response = await fetch(`${getApiUrl()}/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Logout failed: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      // Clear session data regardless of logout success
      clearSession();
    }
  } else {
    clearSession();
  }
};

// Get current session
export const getCurrentSession = (): SessionData | null => {
  return parseStoredSession();
};

// Get current access token
export const getAccessToken = (): string | null => {
  const session = parseStoredSession();
  return session?.tokens?.accessToken || null;
};

// Auth header helper
export const getAuthHeaders = (): HeadersInit => {
  const token = getAccessToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Add a new function to handle session persistence
export const initializeAuthState = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found in localStorage");
      return false;
    }

    const response = await fetch(`${getApiUrl()}/auth/check`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.log("Auth check failed:", response.status);
      clearSession();
      return false;
    }

    const data = await response.json();
    if (!data.success) {
      console.log("Auth check returned unsuccessful:", data.error);
      clearSession();
      return false;
    }

    // Store the updated session data
    const now = Date.now();
    const sessionData: SessionData = {
      user: data.user,
      tokens: {
        accessToken: token,
        refreshToken: "", // We don't use refresh tokens with KV sessions
      },
      expiresAt: new Date(data.session.expiresAt).getTime(),
      lastChecked: now,
    };

    storeSession(sessionData);
    return true;
  } catch (error) {
    console.error("Auth initialization failed:", error);
    clearSession();
    return false;
  }
};

// Add this helper function
export const debugSession = () => {
  const session = parseStoredSession();
  const token = localStorage.getItem("token");

  console.group("Session Debug Info");
  console.log("Stored Session:", session);
  console.log("Token:", token);
  console.log(
    "Session Valid:",
    session?.expiresAt ? new Date(session.expiresAt) > new Date() : false
  );
  console.log(
    "Last Checked:",
    session?.lastChecked ? new Date(session.lastChecked) : "Never"
  );
  console.groupEnd();

  return {
    session,
    token,
    isValid: session?.expiresAt
      ? new Date(session.expiresAt) > new Date()
      : false,
    lastChecked: session?.lastChecked ? new Date(session.lastChecked) : "Never",
  };
};
