import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Upload, Trash2, Ship } from "lucide-react";
import { cn } from "@/lib/utils";
import { useUploadFCLRate, useDeleteFCLRate } from "@/lib/api";

interface FCLRate {
  id: number;
  carrier: string;
  origin: string;
  destination: string;
  created_at: string;
}

export default function FCLRateSettings() {
  const { toast } = useToast();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [rates, setRates] = useState<FCLRate[]>([]);

  const uploadMutation = useUploadFCLRate();
  const deleteMutation = useDeleteFCLRate();

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      validateAndSetFile(file);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      validateAndSetFile(file);
    }
  };

  const validateAndSetFile = (file: File) => {
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    if (!allowedTypes.includes(file.type)) {
      toast({
        title: "Invalid file type",
        description: "Please upload an Excel or CSV file",
        variant: "destructive",
      });
      return;
    }
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast({
        title: "No file selected",
        description: "Please select a file to upload",
        variant: "destructive",
      });
      return;
    }

    try {
      await uploadMutation.mutateAsync(selectedFile);

      toast({
        title: "Upload successful",
        description: "FCL rates have been imported successfully",
      });

      setSelectedFile(null);
    } catch (error) {
      toast({
        title: "Upload failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (rateId: number) => {
    try {
      await deleteMutation.mutateAsync(rateId);

      toast({
        title: "Rate deleted",
        description: "The rate has been deleted successfully",
      });

      setRates(rates.filter((rate) => rate.id !== rateId));
    } catch (error) {
      toast({
        title: "Delete failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        {/* File Upload */}
        <div
          className={cn(
            "border-2 border-dashed rounded-lg p-6 text-center cursor-pointer",
            isDragging ? "border-primary bg-primary/10" : "border-gray-300",
            selectedFile && "border-green-500 bg-green-50"
          )}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.getElementById("fcl-rate-upload")?.click()}
        >
          <input
            id="fcl-rate-upload"
            type="file"
            className="hidden"
            accept=".xlsx,.xls,.csv"
            onChange={handleFileSelect}
          />
          <div className="flex flex-col items-center gap-2">
            <Upload
              className={cn(
                "w-10 h-10",
                selectedFile ? "text-green-500" : "text-gray-400"
              )}
            />
            {selectedFile ? (
              <div>
                <p className="text-sm font-medium">{selectedFile.name}</p>
                <p className="text-xs text-gray-500">
                  {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                </p>
              </div>
            ) : (
              <div>
                <p className="text-sm font-medium">
                  Drop your FCL rate file here or click to browse
                </p>
                <p className="text-xs text-gray-500">Excel or CSV files only</p>
              </div>
            )}
          </div>
        </div>

        <Button
          onClick={handleUpload}
          disabled={!selectedFile || uploadMutation.isPending}
        >
          {uploadMutation.isPending ? "Uploading..." : "Upload FCL Rates"}
        </Button>
      </div>

      {/* Rate List */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium">Imported Rates</h3>
        <ScrollArea className="h-[200px] w-full rounded-md border p-2">
          <div className="space-y-2">
            {rates.map((rate) => (
              <div
                key={rate.id}
                className="flex items-center justify-between p-2 rounded-lg border"
              >
                <div className="flex items-center gap-2">
                  <Ship className="w-5 h-5 text-blue-500" />
                  <div>
                    <p className="text-sm font-medium">{rate.carrier}</p>
                    <p className="text-xs text-gray-500">
                      {rate.origin} → {rate.destination}
                    </p>
                  </div>
                </div>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => handleDelete(rate.id)}
                  disabled={deleteMutation.isPending}
                >
                  <Trash2 className="w-4 h-4 text-red-500" />
                </Button>
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
