import { useState, useEffect } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectSeparator,
  SelectGroup,
  SelectLabel,
} from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useToast } from "@/hooks/use-toast";
import { Upload, FileText, Download, Loader2 } from "lucide-react";
import { getApiUrl } from "@/lib/config";
import { Badge } from "@/components/ui/badge";
import { checkAuthStatus, initializeAuthState } from "@/lib/auth";
import { useLocation } from "wouter";

interface AIModel {
  id: number;
  provider?: string;
  model_name?: string;
  friendly_name?: string | null;
  enabled?: boolean;
}

interface AIModelsResponse {
  success?: boolean;
  models?: AIModel[];
}

interface HSResult {
  code?: {
    hs_code?: string;
    alternative_codes?: string[];
    confidence_score?: number;
  };
  classification_analysis?: {
    primary_classification?: string;
    key_classification_factors?: string[];
    general_rules_interpretation?: string;
    similar_precedents?: string[];
  };
  product_details?: {
    product_type?: string;
    manufacture_stage?: string;
    key_materials?: string[];
    essential_characteristics?: string[];
  };
  trade_requirements?: {
    duty_rates?: {
      base_rate?: string;
      preferential_rate?: string;
      additional_duties?: string[];
    };
    regulatory_compliance?: {
      import_licenses?: string[];
      certifications?: string[];
      technical_standards?: string[];
    };
    trade_agreements?: {
      applicable_ftas?: string[];
      rules_of_origin?: string;
      documentation?: string;
    };
    warnings_and_restrictions?: {
      import_restrictions?: string;
      special_requirements?: string;
      risk_factors?: string;
    };
  };
  classification_hierarchy?: {
    section?: { code?: string; description?: string };
    chapter?: { code?: string; description?: string };
    heading?: { code?: string; description?: string };
    subheading?: { code?: string; description?: string };
  };
  metadata?: {
    classification_date?: string;
    version?: string;
    data_sources?: string[];
  };
}

interface HSLookupResponse {
  success?: boolean;
  result?: HSResult;
}

interface ErrorResponse {
  message?: string;
  error?: string;
  details?: string;
  debug?: string;
}

interface HSLookupFormValues {
  model_id?: number;
  product_overview?: string;
  product_function?: string;
  product_type?: "complete" | "part" | "accessory";
  manufacture_stage?: "finished" | "semi-finished" | "raw";
  materials?: string;
  dimensions?: string;
  industry?: string;
  origin_country?: string;
  target_country?: string;
  hs_digits?: string;
}

interface RecentBulkUpload {
  id?: string;
  filename?: string;
  status?: string;
  timestamp?: string;
  recordCount?: number;
}

interface CascadeModel extends AIModel {
  isCascade?: boolean;
}

interface QuickHSLookupFormValues {
  hs_code: string;
  export_country: string;
  import_country: string;
}

const formSchema = z.object({
  model_id: z.number().optional(),
  product_overview: z.string().optional(),
  product_function: z.string().optional(),
  product_type: z.enum(["complete", "part", "accessory"]).optional(),
  manufacture_stage: z.enum(["finished", "semi-finished", "raw"]).optional(),
  materials: z.string().optional(),
  dimensions: z.string().optional(),
  industry: z.string().optional(),
  origin_country: z.string().optional(),
  target_country: z.string().optional(),
  hs_digits: z.string().optional().default("8"),
});

const quickLookupSchema = z.object({
  hs_code: z.string().min(6, "HS code must be at least 6 digits"),
  export_country: z.string().min(1, "Export country is required"),
  import_country: z.string().min(1, "Import country is required"),
});

const PRODUCT_TYPE_OPTIONS = [
  { value: "complete", label: "Complete Product" },
  { value: "part", label: "Part" },
  { value: "accessory", label: "Accessory" },
];

const MANUFACTURE_STAGE_OPTIONS = [
  { value: "finished", label: "Finished" },
  { value: "semi-finished", label: "Semi-finished" },
  { value: "raw", label: "Raw Material" },
];

const HS_DIGIT_OPTIONS = [
  { value: "6", label: "6 digits" },
  { value: "8", label: "8 digits" },
  { value: "10", label: "10 digits" },
  { value: "12", label: "12 digits" },
  { value: "14", label: "14 digits" },
];

export default function HSLookup() {
  const { toast } = useToast();
  const [result, setResult] = useState<HSLookupResponse["result"] | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isClassifyLoading, setIsClassifyLoading] = useState(false);
  const [isQuickLookupLoading, setIsQuickLookupLoading] = useState(false);
  const [isBulkLoading, setIsBulkLoading] = useState(false);
  const [aiModels, setAIModels] = useState<CascadeModel[]>([]);
  const [recentBulkUploads, setRecentBulkUploads] = useState<
    RecentBulkUpload[]
  >([]);
  const [cascadeState, setCascadeState] = useState<{
    stage: "initial" | "detailed" | "validation" | "complete";
    progress: number;
  }>({
    stage: "initial",
    progress: 0,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [, navigate] = useLocation();

  const form = useForm<HSLookupFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      model_id: undefined,
      product_overview: "",
      product_function: "",
      product_type: undefined,
      manufacture_stage: undefined,
      materials: "",
      dimensions: "",
      industry: "",
      origin_country: "",
      target_country: "",
      hs_digits: "8",
    },
  });

  const quickLookupForm = useForm<QuickHSLookupFormValues>({
    resolver: zodResolver(quickLookupSchema),
    defaultValues: {
      hs_code: "",
      export_country: "",
      import_country: "",
    },
  });

  useEffect(() => {
    const initAuth = async () => {
      const isAuth = await initializeAuthState();
      setIsAuthenticated(isAuth);

      if (!isAuth) {
        navigate("/login");
      } else {
        await fetchAIModels(); // Fetch models after auth is confirmed
      }
    };

    initAuth();
  }, [navigate]);

  useEffect(() => {
    fetchRecentBulkUploads();
  }, []);

  const fetchAIModels = async () => {
    if (!isAuthenticated) return;

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        navigate("/login");
        return;
      }

      console.log("Fetching AI models...");
      const response = await fetch(`${getApiUrl()}/settings/ai-models`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error(
          "AI models fetch failed:",
          response.status,
          response.statusText
        );
        if (response.status === 401) {
          setIsAuthenticated(false);
          navigate("/login");
          return;
        }
        throw new Error(`Failed to fetch AI models: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("AI Models response:", data);

      if (data.success && Array.isArray(data.models)) {
        const enabledModels = data.models.filter((model) => model.enabled);
        console.log("Enabled models:", enabledModels);

        const cascadeOption = {
          id: -1,
          provider: "Anthropic",
          model_name: "cascade",
          friendly_name: "🔄 3-Tier Model Validation",
          enabled: true,
          isCascade: true,
        };

        const allModels = [...enabledModels, cascadeOption];
        console.log("All models with cascade:", allModels);
        setAIModels(allModels);

        const defaultModel = enabledModels.find(
          (model) => model.model_name === "claude-3-5-haiku-20241022"
        );

        if (defaultModel) {
          console.log("Setting default model:", defaultModel);
          form.setValue("model_id", defaultModel.id, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
      } else {
        console.error("Invalid AI models response:", data);
        toast({
          title: "Error",
          description: "Failed to load AI models: Invalid response format",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error("Error fetching AI models:", error);
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to load AI models",
        variant: "destructive",
      });
    }
  };

  const fetchRecentBulkUploads = async () => {
    try {
      const response = await fetch(`${getApiUrl()}/hs-lookup/recent-bulk`);
      if (!response.ok) {
        throw new Error("Failed to fetch recent bulk uploads");
      }
      const data = await response.json();
      setRecentBulkUploads(data.uploads || []);
    } catch (error) {
      console.error("Failed to fetch recent bulk uploads:", error);
    }
  };

  const validateSelectedModel = (modelId: number) => {
    const model = aiModels.find((m) => m.id === modelId);
    if (!model) {
      toast({
        title: "Error",
        description: "Please select an AI model",
        variant: "destructive",
      });
      return false;
    }

    if (model.isCascade) {
      return true;
    }

    const supportedModels = [
      "claude-3-opus-20240229",
      "claude-3-sonnet-20240229",
      "claude-3-haiku-20240307",
      "claude-3-5-sonnet-20240620",
      "claude-3-5-haiku-20241022",
      "claude-3-5-sonnet",
      "claude-3-5-haiku",
      "claude-3.5-sonnet",
      "claude-3.5-haiku",
    ];

    const isSupported = supportedModels.some(
      (supported) => model.model_name?.toLowerCase() === supported.toLowerCase()
    );

    if (!isSupported) {
      console.log("Unsupported model:", model.model_name);
      toast({
        title: "Invalid Model",
        description: "Please select a supported Claude 3 model",
        variant: "destructive",
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (values: HSLookupFormValues) => {
    try {
      if (!values.model_id) {
        toast({
          title: "Error",
          description: "Please select an AI model",
          variant: "destructive",
        });
        return;
      }

      const selectedModel = aiModels.find((m) => m.id === values.model_id);
      if (!selectedModel) {
        toast({
          title: "Error",
          description: "Invalid model selection",
          variant: "destructive",
        });
        return;
      }

      if (!selectedModel.isCascade && !validateSelectedModel(values.model_id)) {
        return;
      }

      setIsClassifyLoading(true);
      const isCascadeModel = selectedModel.isCascade;

      if (isCascadeModel) {
        setCascadeState({ stage: "initial", progress: 0 });
      }

      const response = await fetch(`${getApiUrl()}/hs-lookup/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          product_overview: values.product_overview || undefined,
          product_function: values.product_function || undefined,
          product_type: values.product_type || undefined,
          manufacture_stage: values.manufacture_stage || undefined,
          materials: values.materials || undefined,
          dimensions: values.dimensions || undefined,
          industry: values.industry || undefined,
          origin_country: values.origin_country || undefined,
          target_country: values.target_country || undefined,
          hsDigits: parseInt(values.hs_digits || "12"),
          selectedModel: values.model_id,
          useCascade: isCascadeModel,
        }),
      });

      if (isCascadeModel) {
        setCascadeState({ stage: "detailed", progress: 33 });
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setCascadeState({ stage: "validation", progress: 66 });
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! status: ${response.status}`
        );
      }

      const data = (await response.json()) as HSLookupResponse;
      if (!data.success || !data.result) {
        throw new Error("Invalid response format");
      }

      if (isCascadeModel) {
        setCascadeState({ stage: "complete", progress: 100 });
      }
      setResult(data.result);
    } catch (error) {
      console.error("Classification error:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to classify product",
      });
    } finally {
      setIsClassifyLoading(false);
    }
  };

  const onQuickLookup = async (values: QuickHSLookupFormValues) => {
    try {
      setIsQuickLookupLoading(true);
      const response = await fetch(`${getApiUrl()}/hs-lookup/quick-search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error("Failed to lookup HS code");
      }

      const data = await response.json();
      setResult(data.result);
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to lookup HS code",
      });
    } finally {
      setIsQuickLookupLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleBulkUpload = async () => {
    if (!selectedFile) return;

    try {
      setIsBulkLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("model_id", form.getValues("model_id").toString());
      formData.append("hs_digits", form.getValues("hs_digits"));

      const response = await fetch(`${getApiUrl()}/hs-lookup/bulk`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        toast({
          title: "Success",
          description: "Bulk upload processed successfully",
          variant: "default",
        });
        setSelectedFile(null);
      } else {
        throw new Error(data.error || "Failed to process bulk upload");
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Failed to process bulk upload: " + (error as Error).message,
        variant: "destructive",
      });
    } finally {
      setIsBulkLoading(false);
    }
  };

  const formatHSCode = (
    code: string | undefined | null,
    digits: number
  ): string => {
    if (!code) return "N/A";
    if (!digits || isNaN(digits)) return code;

    try {
      const formattedCode = code.slice(0, digits);
      return formattedCode.replace(
        /(\d{4})(\d{2})?(\d{2})?(\d{2})?(\d{2})?/,
        (_, p1, p2, p3, p4, p5) => {
          let result = p1 || "";
          if (p2) result += "." + p2;
          if (p3) result += "." + p3;
          if (p4) result += "." + p4;
          if (p5) result += "." + p5;
          return result;
        }
      );
    } catch (error) {
      console.error("Error formatting HS code:", error);
      return code;
    }
  };

  const renderCascadeProgress = () => {
    if (!isClassifyLoading) return null;

    return (
      <div className="space-y-2">
        <div className="text-sm font-medium">
          {cascadeState.stage === "initial" && "Initial Analysis..."}
          {cascadeState.stage === "detailed" && "Detailed Review..."}
          {cascadeState.stage === "validation" && "Final Validation..."}
          {cascadeState.stage === "complete" && "Complete"}
        </div>
        <div className="w-full bg-secondary h-2 rounded-full">
          <div
            className="bg-primary h-2 rounded-full transition-all duration-300"
            style={{ width: `${cascadeState.progress}%` }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column */}
        <div className="space-y-6">
          {/* Quick HS Code Lookup */}
          <Card>
            <CardHeader>
              <CardTitle>Quick HS Code Lookup</CardTitle>
            </CardHeader>
            <CardContent>
              <Form {...quickLookupForm}>
                <form
                  onSubmit={quickLookupForm.handleSubmit(onQuickLookup)}
                  className="space-y-4"
                >
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <FormField
                      control={quickLookupForm.control}
                      name="hs_code"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>HS Code</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Enter HS code" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={quickLookupForm.control}
                      name="export_country"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Export Country</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Country of export" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={quickLookupForm.control}
                      name="import_country"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Import Country</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Country of import" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <Button
                    type="submit"
                    disabled={isQuickLookupLoading}
                    variant="default"
                    className="w-full"
                  >
                    {isQuickLookupLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Looking up...
                      </>
                    ) : (
                      "Search HS Code"
                    )}
                  </Button>
                </form>
              </Form>
            </CardContent>
          </Card>

          {/* Single Item Lookup */}
          <Card>
            <CardHeader>
              <CardTitle>Single Item Lookup</CardTitle>
            </CardHeader>
            <CardContent>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-4"
                >
                  <FormField
                    control={form.control}
                    name="model_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>AI Model</FormLabel>
                        <Select
                          onValueChange={(value) => {
                            console.log("Selected model value:", value);
                            field.onChange(Number(value));
                          }}
                          value={field.value?.toString() || ""}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select AI model">
                                {field.value
                                  ? aiModels.find((m) => m.id === field.value)
                                      ?.friendly_name || "Select AI model"
                                  : "Select AI model"}
                              </SelectValue>
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {/* Regular models */}
                            <SelectGroup>
                              <SelectLabel>Available Models</SelectLabel>
                              {aiModels
                                .filter(
                                  (model) => !model.isCascade && model.enabled
                                )
                                .map((model) => (
                                  <SelectItem
                                    key={model.id}
                                    value={model.id.toString()}
                                  >
                                    {model.friendly_name || model.model_name}
                                  </SelectItem>
                                ))}
                            </SelectGroup>

                            {/* Cascade option */}
                            <SelectSeparator />
                            <SelectGroup>
                              <SelectLabel>Advanced Options</SelectLabel>
                              {aiModels
                                .filter((model) => model.isCascade)
                                .map((model) => (
                                  <SelectItem
                                    key={model.id}
                                    value={model.id.toString()}
                                    className="font-medium text-primary"
                                  >
                                    {model.friendly_name}
                                  </SelectItem>
                                ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <FormDescription>
                          Select an AI model for classification or use 3-Tier
                          validation
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="product_overview"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Product Overview</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="Provide a detailed description of the product"
                            className="resize-none h-32"
                            {...field}
                          />
                        </FormControl>
                        <FormDescription>
                          Include key characteristics, composition, and any
                          unique features
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="product_function"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          What is the function/purpose of the product?
                        </FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="Describe how the product works and what it's used for"
                            className="resize-none h-24"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="product_type"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Product Type</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select product type" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {PRODUCT_TYPE_OPTIONS.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="manufacture_stage"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Manufacturing Stage</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select stage" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {MANUFACTURE_STAGE_OPTIONS.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="materials"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Materials</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="List the main materials used in the product"
                            className="resize-none"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="dimensions"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Physical Specifications</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="Dimensions, weight, or capacity specifications"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="industry"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Industry/Sector</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="Primary industry or sector where the product is used"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="origin_country"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Country of Origin</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="Manufacturing country"
                            />
                          </FormControl>
                          <FormDescription>
                            Used for trade compliance analysis
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="target_country"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Import Country</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder="Destination country"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="hs_digits"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>HS Code Length</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select digit length" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {HS_DIGIT_OPTIONS.map((option) => (
                              <SelectItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Button
                    type="submit"
                    disabled={isClassifyLoading}
                    variant="default"
                    className="w-full"
                  >
                    {isClassifyLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Analyzing Product...
                      </>
                    ) : (
                      "Classify Product"
                    )}
                  </Button>
                </form>
              </Form>
            </CardContent>
          </Card>

          {/* Bulk Upload */}
          <Card>
            <CardHeader>
              <CardTitle>Bulk Upload</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div
                  className="border-2 border-dashed rounded-lg p-6 text-center cursor-pointer hover:border-primary transition-colors"
                  onClick={() =>
                    document.getElementById("file-upload")?.click()
                  }
                >
                  <Upload className="mx-auto h-8 w-8 text-muted-foreground" />
                  <div className="mt-2">Click to upload or drag and drop</div>
                  <div className="text-xs text-muted-foreground">
                    Excel or CSV files supported
                  </div>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    accept=".xlsx,.xls,.csv"
                    onChange={handleFileChange}
                  />
                </div>
                {selectedFile && (
                  <div className="flex items-center gap-2 text-sm">
                    <FileText className="h-4 w-4" />
                    {selectedFile.name}
                  </div>
                )}
                <Button
                  onClick={handleBulkUpload}
                  disabled={!selectedFile || isBulkLoading}
                  variant="secondary"
                  className="w-full"
                >
                  {isBulkLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    "Upload and Process"
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          {/* Single Lookup Results */}
          {result && (
            <>
              <Card>
                <CardHeader>
                  <CardTitle>Classification Results</CardTitle>
                </CardHeader>
                <CardContent>
                  {renderCascadeProgress()}
                  <div className="space-y-6">
                    {/* Primary Classification */}
                    <div className="space-y-4">
                      <div>
                        <div className="text-sm text-muted-foreground">
                          HS Code
                        </div>
                        <div className="text-lg font-semibold">
                          {result?.code?.hs_code
                            ? formatHSCode(
                                result.code.hs_code,
                                parseInt(form.getValues("hs_digits") || "8")
                              )
                            : "N/A"}
                        </div>
                        <div className="text-sm text-muted-foreground mt-1">
                          Confidence: {result.code?.confidence_score || 0}%
                        </div>
                      </div>

                      {/* Classification Analysis */}
                      <div className="border-t pt-4">
                        <div className="text-sm font-medium mb-2">
                          Classification Analysis
                        </div>
                        <div className="space-y-3">
                          <div>
                            <div className="text-sm font-medium">
                              Primary Classification
                            </div>
                            <div className="text-sm">
                              {result.classification_analysis
                                ?.primary_classification || "N/A"}
                            </div>
                          </div>
                          {result.classification_analysis
                            ?.key_classification_factors?.length > 0 && (
                            <div>
                              <div className="text-sm font-medium">
                                Key Factors
                              </div>
                              <ul className="list-disc pl-4 text-sm">
                                {result.classification_analysis.key_classification_factors.map(
                                  (factor, idx) => (
                                    <li key={idx}>{factor}</li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                          <div>
                            <div className="text-sm font-medium">
                              GRI Application
                            </div>
                            <div className="text-sm">
                              {result.classification_analysis
                                ?.general_rules_interpretation || "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Product Details */}
                      <div className="border-t pt-4">
                        <div className="text-sm font-medium mb-2">
                          Product Details
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <div className="text-sm font-medium">Type</div>
                            <div className="text-sm">
                              {result.product_details.product_type}
                            </div>
                          </div>
                          <div>
                            <div className="text-sm font-medium">Stage</div>
                            <div className="text-sm">
                              {result.product_details.manufacture_stage}
                            </div>
                          </div>
                          <div className="col-span-2">
                            <div className="text-sm font-medium">
                              Key Materials
                            </div>
                            <div className="text-sm">
                              {result.product_details.key_materials.join(", ")}
                            </div>
                          </div>
                          <div className="col-span-2">
                            <div className="text-sm font-medium">
                              Essential Characteristics
                            </div>
                            <ul className="list-disc pl-4 text-sm">
                              {result.product_details.essential_characteristics.map(
                                (char, idx) => (
                                  <li key={idx}>{char}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>

                      {/* Trade Requirements */}
                      {result.trade_requirements && (
                        <div className="border-t pt-4">
                          <div className="text-sm font-medium mb-2">
                            Trade Requirements
                          </div>

                          {/* Duty Rates */}
                          <div className="mb-4">
                            <div className="text-sm font-medium">
                              Duty Rates
                            </div>
                            <div className="grid grid-cols-2 gap-2 text-sm">
                              <div>
                                Base Rate:{" "}
                                {result.trade_requirements.duty_rates.base_rate}
                              </div>
                              <div>
                                Preferential:{" "}
                                {
                                  result.trade_requirements.duty_rates
                                    .preferential_rate
                                }
                              </div>
                            </div>
                            {result.trade_requirements.duty_rates
                              .additional_duties.length > 0 && (
                              <div className="mt-2">
                                <div className="text-sm font-medium">
                                  Additional Duties
                                </div>
                                <ul className="list-disc pl-4 text-sm">
                                  {result.trade_requirements.duty_rates.additional_duties.map(
                                    (duty, idx) => (
                                      <li key={idx}>{duty}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>

                          {/* Regulatory Compliance */}
                          <div className="mb-4">
                            <div className="text-sm font-medium">
                              Regulatory Requirements
                            </div>
                            <div className="space-y-2">
                              {result.trade_requirements.regulatory_compliance
                                .import_licenses.length > 0 && (
                                <div>
                                  <div className="text-sm font-medium">
                                    Import Licenses
                                  </div>
                                  <ul className="list-disc pl-4 text-sm">
                                    {result.trade_requirements.regulatory_compliance.import_licenses.map(
                                      (license, idx) => (
                                        <li key={idx}>{license}</li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                              {result.trade_requirements.regulatory_compliance
                                .certifications.length > 0 && (
                                <div>
                                  <div className="text-sm font-medium">
                                    Required Certifications
                                  </div>
                                  <ul className="list-disc pl-4 text-sm">
                                    {result.trade_requirements.regulatory_compliance.certifications.map(
                                      (cert, idx) => (
                                        <li key={idx}>{cert}</li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Warnings and Restrictions */}
                          {result?.trade_requirements
                            ?.warnings_and_restrictions && (
                            <div className="bg-red-50 dark:bg-red-900/10 p-3 rounded-md">
                              <div className="text-sm font-medium text-red-600 dark:text-red-400">
                                Important Warnings
                              </div>
                              <div className="space-y-2 text-sm text-red-600 dark:text-red-400">
                                {result.trade_requirements
                                  .warnings_and_restrictions.risk_factors && (
                                  <div>
                                    {typeof result.trade_requirements
                                      .warnings_and_restrictions
                                      .risk_factors === "string"
                                      ? result.trade_requirements
                                          .warnings_and_restrictions
                                          .risk_factors
                                      : Array.isArray(
                                          result.trade_requirements
                                            .warnings_and_restrictions
                                            .risk_factors
                                        )
                                      ? result.trade_requirements.warnings_and_restrictions.risk_factors.map(
                                          (risk, idx) => (
                                            <div key={idx}>{risk}</div>
                                          )
                                        )
                                      : null}
                                  </div>
                                )}
                                {result.trade_requirements
                                  .warnings_and_restrictions
                                  .import_restrictions && (
                                  <div>
                                    {
                                      result.trade_requirements
                                        .warnings_and_restrictions
                                        .import_restrictions
                                    }
                                  </div>
                                )}
                                {result.trade_requirements
                                  .warnings_and_restrictions
                                  .special_requirements && (
                                  <div>
                                    {
                                      result.trade_requirements
                                        .warnings_and_restrictions
                                        .special_requirements
                                    }
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Classification Hierarchy */}
                      <div className="border-t pt-4">
                        <div className="text-sm font-medium mb-2">
                          Classification Hierarchy
                        </div>
                        <div className="space-y-2">
                          {Object.entries(result.classification_hierarchy).map(
                            ([level, data]) => (
                              <div key={level}>
                                <div className="text-sm font-medium capitalize">
                                  {level}
                                </div>
                                <div className="text-sm">
                                  {data.code} - {data.description}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      {/* Alternative Codes */}
                      {result?.code?.alternative_codes?.length > 0 && (
                        <div className="border-t pt-4">
                          <div className="text-sm font-medium mb-2">
                            Alternative Classifications
                          </div>
                          <div className="space-y-2">
                            {result.code.alternative_codes.map((code, idx) => (
                              <div key={idx} className="text-sm">
                                {code
                                  ? formatHSCode(
                                      code,
                                      parseInt(
                                        form.getValues("hs_digits") || "8"
                                      )
                                    )
                                  : "N/A"}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Metadata */}
                      <div className="border-t pt-4 text-sm text-muted-foreground">
                        <div>
                          Classification Date:{" "}
                          {result.metadata.classification_date}
                        </div>
                        <div>Version: {result.metadata.version}</div>
                        <div>
                          Sources: {result.metadata.data_sources.join(", ")}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {result && (
                <Card>
                  <CardHeader>
                    <CardTitle>HS Code Details</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-6">
                      {/* HS Code and Basic Info */}
                      <div className="space-y-4">
                        <div>
                          <div className="text-sm text-muted-foreground">
                            HS Code
                          </div>
                          <div className="text-lg font-semibold">
                            {formatHSCode(
                              result.code.hs_code,
                              result.code.hs_code.length
                            )}
                          </div>
                        </div>

                        {/* Classification Hierarchy */}
                        <div className="border-t pt-4">
                          <div className="text-sm font-medium mb-2">
                            Classification Details
                          </div>
                          <div className="space-y-2">
                            {Object.entries(
                              result.classification_hierarchy
                            ).map(([level, data]) => (
                              <div
                                key={level}
                                className="border-b pb-2 last:border-0"
                              >
                                <div className="text-sm font-medium capitalize">
                                  {level}
                                </div>
                                <div className="text-sm">
                                  {data.code} - {data.description}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Duty Information */}
                        {result.trade_requirements?.duty_rates && (
                          <div className="border-t pt-4">
                            <div className="text-sm font-medium mb-2">
                              Duty Information
                            </div>
                            <div className="space-y-2">
                              <div>
                                Base Rate:{" "}
                                {result.trade_requirements.duty_rates.base_rate}
                              </div>
                              <div>
                                Preferential Rate:{" "}
                                {
                                  result.trade_requirements.duty_rates
                                    .preferential_rate
                                }
                              </div>
                              {result.trade_requirements.duty_rates
                                .additional_duties.length > 0 && (
                                <div>
                                  <div className="text-sm font-medium">
                                    Additional Duties
                                  </div>
                                  <ul className="list-disc pl-4">
                                    {result.trade_requirements.duty_rates.additional_duties.map(
                                      (duty, idx) => (
                                        <li key={idx}>{duty}</li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {/* Import Requirements */}
                        {result.trade_requirements?.regulatory_compliance && (
                          <div className="border-t pt-4">
                            <div className="text-sm font-medium mb-2">
                              Import Requirements
                            </div>
                            <div className="space-y-2">
                              {result.trade_requirements.regulatory_compliance
                                .import_licenses.length > 0 && (
                                <div>
                                  <div className="text-sm font-medium">
                                    Licenses Required
                                  </div>
                                  <ul className="list-disc pl-4">
                                    {result.trade_requirements.regulatory_compliance.import_licenses.map(
                                      (license, idx) => (
                                        <li key={idx}>{license}</li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {/* AHECC Link */}
                        <div className="border-t pt-4">
                          <div className="text-sm font-medium mb-2">
                            Reference Links
                          </div>
                          <a
                            href={`https://www.abf.gov.au/importing-exporting-and-manufacturing/tariff-classification/current-tariff/schedule-3/section-${result.classification_hierarchy.section.code}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline"
                          >
                            View AHECC Details →
                          </a>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}
            </>
          )}

          {/* Recent Bulk Uploads */}
          <Card>
            <CardHeader>
              <CardTitle>Recent Bulk Uploads</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {recentBulkUploads.length > 0 ? (
                  recentBulkUploads.map((upload) => (
                    <div
                      key={upload.id}
                      className="flex items-center justify-between p-2 border rounded-lg"
                    >
                      <div className="flex items-center space-x-2">
                        <FileText className="h-4 w-4 text-muted-foreground" />
                        <div>
                          <div className="font-medium">{upload.filename}</div>
                          <div className="text-sm text-muted-foreground">
                            {new Date(upload.timestamp).toLocaleDateString()} •{" "}
                            {upload.recordCount || 0} records
                          </div>
                        </div>
                      </div>
                      <Badge
                        variant={
                          upload.status === "completed"
                            ? "success"
                            : "secondary"
                        }
                      >
                        {upload.status}
                      </Badge>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-muted-foreground">
                    No recent bulk uploads
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
