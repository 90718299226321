import { useState, useEffect } from "react";
import { useLocation } from "wouter";
import { checkAuthStatus } from "@/lib/auth";
import { useToast } from "@/components/ui/use-toast";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Progress } from "@/components/ui/progress";
import { Upload, Download, FileType } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  useTemplates,
  useUploadForConversion,
  useConversionStatus,
  useRecentConversions,
} from "@/lib/api";

interface ConversionJob {
  id: number;
  status: string;
  progress: number;
  error: string | null;
  originalFilename: string;
  templateName: string | null;
  createdAt: string;
  completedAt: string | null;
  downloadUrl: string | null;
}

export default function Convert() {
  const [, navigate] = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { toast } = useToast();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [isDragging, setIsDragging] = useState(false);
  const [currentJobId, setCurrentJobId] = useState<string | null>(null);

  const { data: templatesData } = useTemplates();
  const uploadMutation = useUploadForConversion();
  const { data: conversionStatus } = useConversionStatus(currentJobId);
  const { data: recentConversions } = useRecentConversions();

  useEffect(() => {
    const verifyAuth = async () => {
      const isAuth = await checkAuthStatus();
      setIsAuthenticated(isAuth);

      if (!isAuth) {
        navigate("/login");
      }
    };

    verifyAuth();
  }, [navigate]);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      validateAndSetFile(file);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      validateAndSetFile(file);
    }
  };

  const validateAndSetFile = (file: File) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/heic"];
    if (!allowedTypes.includes(file.type)) {
      toast({
        title: "Invalid file type",
        description: "Please upload a PNG, JPEG, or HEIC file",
        variant: "destructive",
      });
      return;
    }
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (!selectedFile || !selectedTemplate) {
      toast({
        title: "Missing required fields",
        description: "Please select a file and template",
        variant: "destructive",
      });
      return;
    }

    try {
      const result = await uploadMutation.mutateAsync({
        file: selectedFile,
        templateId: selectedTemplate,
      });

      if (result.success) {
        setCurrentJobId(String(result.jobId));
        toast({
          title: "Upload successful",
          description: "Your file is being processed",
        });
      }
    } catch (error) {
      toast({
        title: "Upload failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Upload Section */}
        <Card>
          <CardHeader>
            <CardTitle>Convert to Excel</CardTitle>
            <CardDescription>
              Upload an image to convert it to Excel format using a template
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            {/* File Upload */}
            <div
              className={cn(
                "border-2 border-dashed rounded-lg p-8 text-center cursor-pointer",
                isDragging ? "border-primary bg-primary/10" : "border-gray-300",
                selectedFile && "border-green-500 bg-green-50"
              )}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={() => document.getElementById("file-upload")?.click()}
            >
              <input
                id="file-upload"
                type="file"
                className="hidden"
                accept=".png,.jpg,.jpeg,.heic"
                onChange={handleFileSelect}
              />
              <div className="flex flex-col items-center gap-2">
                <Upload
                  className={cn(
                    "w-10 h-10",
                    selectedFile ? "text-green-500" : "text-gray-400"
                  )}
                />
                {selectedFile ? (
                  <div>
                    <p className="text-sm font-medium">{selectedFile.name}</p>
                    <p className="text-xs text-gray-500">
                      {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-sm font-medium">
                      Drop your file here or click to browse
                    </p>
                    <p className="text-xs text-gray-500">
                      PNG, JPEG, or HEIC files only
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* Template Selection */}
            <div className="space-y-2">
              <Label htmlFor="template">Select Template</Label>
              <Select
                value={selectedTemplate}
                onValueChange={setSelectedTemplate}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Choose a template" />
                </SelectTrigger>
                <SelectContent>
                  {templatesData?.templates?.map((template) => (
                    <SelectItem key={template.id} value={String(template.id)}>
                      {template.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {/* Upload Button */}
            <Button
              className="w-full"
              onClick={handleUpload}
              disabled={
                !selectedFile || !selectedTemplate || uploadMutation.isPending
              }
            >
              {uploadMutation.isPending ? "Uploading..." : "Upload and Convert"}
            </Button>

            {/* Conversion Progress */}
            {currentJobId && conversionStatus?.job && (
              <div className="space-y-2">
                <div className="flex justify-between text-sm">
                  <span>Converting...</span>
                  <span>{conversionStatus.job.progress}%</span>
                </div>
                <Progress value={conversionStatus.job.progress} />
                {conversionStatus.job.error && (
                  <p className="text-sm text-red-500">
                    Error: {conversionStatus.job.error}
                  </p>
                )}
              </div>
            )}
          </CardContent>
        </Card>

        {/* Recent Conversions */}
        <Card>
          <CardHeader>
            <CardTitle>Recent Conversions</CardTitle>
            <CardDescription>
              View and download your recent conversions
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[400px] pr-4">
              <div className="space-y-4">
                {recentConversions?.jobs?.map((job) => (
                  <div
                    key={job.id}
                    className="flex items-center justify-between p-3 border rounded-lg"
                  >
                    <div className="flex items-center gap-3">
                      <FileType className="w-8 h-8 text-blue-500" />
                      <div>
                        <p className="text-sm font-medium">
                          {job.originalFilename}
                        </p>
                        <p className="text-xs text-gray-500">
                          Template: {job.templateName}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      {job.status === "completed" && job.downloadUrl ? (
                        <Button size="sm" variant="outline" asChild>
                          <a href={job.downloadUrl} download>
                            <Download className="w-4 h-4 mr-1" />
                            Download
                          </a>
                        </Button>
                      ) : (
                        <Button size="sm" variant="outline" disabled>
                          {job.status === "failed" ? "Failed" : job.status}
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
