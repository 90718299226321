import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Brain, Plus, Trash2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  AIModel,
  FeatureModelSetting,
  getAIModels,
  addAIModel,
  updateAIModel,
  deleteAIModel,
  getFeatureModelSettings,
  updateFeatureModelSetting,
} from "@/lib/api";

type Provider = "openai" | "anthropic" | "perplexity";

const PROVIDER_NAMES = {
  openai: "OpenAI",
  anthropic: "Claude",
  perplexity: "Perplexity",
};

const PROVIDER_ENV_KEYS = {
  openai: "OPENAI_API",
  anthropic: "CLAUDE_API",
  perplexity: "PERPLEXITY_API",
};

const FEATURE_NAMES = {
  convert_to_excel: "Convert to Excel",
  hs_lookup: "HS Lookup",
  document_check: "Document Check",
  chat: "Chat",
};

interface NewModel {
  provider: Provider;
  friendly_name: string;
  model_name: string;
}

export default function AIModelSettings() {
  const { toast } = useToast();
  const [models, setModels] = useState<AIModel[]>([]);
  const [featureSettings, setFeatureSettings] = useState<FeatureModelSetting[]>(
    []
  );
  const [isAddingModel, setIsAddingModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newModel, setNewModel] = useState<NewModel>({
    provider: "openai",
    friendly_name: "",
    model_name: "",
  });

  // Fetch models and feature settings on component mount
  useEffect(() => {
    fetchModels();
    fetchFeatureSettings();
  }, []);

  const fetchModels = async () => {
    try {
      const response = await getAIModels();
      if (response.success) {
        setModels(response.models);
      }
    } catch (error) {
      console.error("Failed to fetch models:", error);
      toast({
        title: "Error",
        description: "Failed to load AI models",
        variant: "destructive",
      });
    }
  };

  const fetchFeatureSettings = async () => {
    try {
      const response = await getFeatureModelSettings();
      if (response.success) {
        setFeatureSettings(response.settings);
      }
    } catch (error) {
      console.error("Failed to fetch feature settings:", error);
      toast({
        title: "Error",
        description: "Failed to load feature settings",
        variant: "destructive",
      });
    }
  };

  const handleAddModel = async () => {
    setIsLoading(true);
    try {
      const response = await addAIModel({
        provider: newModel.provider,
        model_name: newModel.model_name,
        friendly_name: newModel.friendly_name,
      });

      if (response.success) {
        toast({
          title: "Success",
          description: "AI model has been added successfully",
        });

        setIsAddingModel(false);
        setNewModel({
          provider: "openai",
          friendly_name: "",
          model_name: "",
        });
        fetchModels(); // Refresh the list
      }
    } catch (error) {
      console.error("Failed to add model:", error);
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to add AI model",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteModel = async (modelId: number) => {
    try {
      const response = await deleteAIModel(modelId);

      if (response.success) {
        toast({
          title: "Success",
          description: "AI model has been deleted successfully",
        });
        fetchModels(); // Refresh the list
      }
    } catch (error) {
      console.error("Failed to delete model:", error);
      toast({
        title: "Error",
        description: "Failed to delete AI model",
        variant: "destructive",
      });
    }
  };

  const handleToggleModel = async (modelId: number, enabled: boolean) => {
    try {
      const response = await updateAIModel(modelId, enabled);

      if (response.success) {
        toast({
          title: "Success",
          description: `AI model has been ${enabled ? "enabled" : "disabled"}`,
        });
        fetchModels(); // Refresh the list
      }
    } catch (error) {
      console.error("Failed to update model:", error);
      toast({
        title: "Error",
        description: "Failed to update AI model",
        variant: "destructive",
      });
    }
  };

  const handleUpdateFeatureModel = async (feature: string, modelId: number) => {
    try {
      const response = await updateFeatureModelSetting(feature, modelId);
      if (response.success) {
        toast({
          title: "Success",
          description: "Feature model has been updated successfully",
        });
        fetchFeatureSettings(); // Refresh the settings
      }
    } catch (error) {
      console.error("Failed to update feature model:", error);
      toast({
        title: "Error",
        description: "Failed to update feature model",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <Brain className="w-5 h-5 text-blue-500" />
            <h3 className="text-lg font-medium">AI Models</h3>
          </div>
          <Dialog open={isAddingModel} onOpenChange={setIsAddingModel}>
            <DialogTrigger asChild>
              <Button size="sm">
                <Plus className="w-4 h-4 mr-2" />
                Add Model
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add AI Model</DialogTitle>
                <DialogDescription>
                  Add a new AI model to use in the system.
                </DialogDescription>
              </DialogHeader>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label>Provider</Label>
                  <Select
                    value={newModel.provider}
                    onValueChange={(value: Provider) =>
                      setNewModel({ ...newModel, provider: value })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select provider" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="anthropic">Claude</SelectItem>
                      <SelectItem value="openai">OpenAI</SelectItem>
                      <SelectItem value="perplexity">Perplexity</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label>Display Name</Label>
                  <Input
                    value={newModel.friendly_name}
                    onChange={(e) =>
                      setNewModel({
                        ...newModel,
                        friendly_name: e.target.value,
                      })
                    }
                    placeholder="e.g., Claude 3 Sonnet"
                  />
                  <p className="text-sm text-gray-500">
                    This is the name that will appear in model selection
                    dropdowns
                  </p>
                </div>
                <div className="space-y-2">
                  <Label>Model Name</Label>
                  <Input
                    value={newModel.model_name}
                    onChange={(e) =>
                      setNewModel({ ...newModel, model_name: e.target.value })
                    }
                    placeholder="e.g., claude-3-sonnet-20240229"
                  />
                  <p className="text-sm text-gray-500">
                    The technical model name used for API calls
                  </p>
                </div>
              </div>
              <DialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsAddingModel(false)}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleAddModel}
                  disabled={
                    isLoading ||
                    !newModel.provider ||
                    !newModel.friendly_name ||
                    !newModel.model_name
                  }
                >
                  {isLoading ? "Adding..." : "Add Model"}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>

        <ScrollArea className="h-[300px] pr-4">
          <div className="space-y-4">
            {models.map((model) => (
              <div
                key={model.id}
                className="flex items-center justify-between p-3 border rounded-lg"
              >
                <div className="flex items-center gap-2">
                  <Brain className="w-5 h-5 text-blue-500" />
                  <div>
                    <p className="text-sm font-medium">{model.friendly_name}</p>
                    <p className="text-xs text-gray-500">
                      {PROVIDER_NAMES[model.provider as Provider]} ·{" "}
                      {model.model_name}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => handleToggleModel(model.id, !model.enabled)}
                  >
                    {model.enabled ? "Disable" : "Enable"}
                  </Button>
                  {model.is_custom && (
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => handleDeleteModel(model.id)}
                    >
                      <Trash2 className="w-4 h-4 text-red-500" />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </Card>

      <Card className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <Brain className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-medium">Feature Models</h3>
        </div>

        <div className="space-y-4">
          {Object.entries(FEATURE_NAMES).map(([feature, label]) => {
            const setting = featureSettings.find(
              (s) => s.feature_name === feature
            );
            return (
              <div key={feature} className="space-y-2">
                <Label>{label}</Label>
                <Select
                  value={setting?.model_id.toString()}
                  onValueChange={(value) =>
                    handleUpdateFeatureModel(feature, parseInt(value))
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select model" />
                  </SelectTrigger>
                  <SelectContent>
                    {models
                      .filter((m) => m.enabled)
                      .map((model) => (
                        <SelectItem key={model.id} value={model.id.toString()}>
                          {model.friendly_name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
                {setting && (
                  <p className="text-sm text-gray-500">
                    Currently using {setting.friendly_name} (
                    {PROVIDER_NAMES[setting.provider as Provider]})
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
}
