import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Upload, Trash2, FileText, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { getApiUrl } from "@/lib/config";

interface HSDocument {
  id: string;
  name: string;
  type: "HS" | "Schedule B";
  created_at: string;
}

export default function HSLookupSettings() {
  const { toast } = useToast();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [documents, setDocuments] = useState<HSDocument[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [headerRow, setHeaderRow] = useState<string>("6");
  const [country, setCountry] = useState<string>("");
  const [version, setVersion] = useState<string>("2022");
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    validateAndSetFiles(files);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    validateAndSetFiles(files);
  };

  const validateAndSetFiles = (files: File[]) => {
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];

    const validFiles = files.filter(file => allowedTypes.includes(file.type));
    const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      toast({
        title: "Invalid file type(s)",
        description: "Some files were skipped. Please upload only Excel or CSV files.",
        variant: "destructive",
      });
    }

    setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0 || !country) {
      toast({
        title: "Missing information",
        description: "Please select files and country code",
        variant: "destructive",
      });
      return;
    }

    setIsUploading(true);
    setUploadProgress({});
    let successCount = 0;
    let failCount = 0;

    try {
      for (const [index, file] of selectedFiles.entries()) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("country", country);
        formData.append("version", version);
        formData.append("headerRow", headerRow);

        try {
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: 0
          }));

          const response = await fetch(`${getApiUrl()}/settings/hs-codes/import`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: formData,
          });

          if (!response.ok) throw new Error(`Failed to import ${file.name}`);

          const result = await response.json();
          successCount++;
          
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: 100
          }));

          toast({
            title: `Imported ${file.name}`,
            description: `Successfully imported ${result.stats?.successful || 0} HS codes`,
          });
        } catch (error) {
          failCount++;
          console.error(`Error uploading ${file.name}:`, error);
          toast({
            title: `Failed to import ${file.name}`,
            description: error instanceof Error ? error.message : "An error occurred",
            variant: "destructive",
          });
        }
      }

      if (successCount > 0) {
        setSelectedFiles([]);
        loadDocuments();
      }
    } finally {
      setIsUploading(false);
      setUploadProgress({});
    }
  };

  const loadDocuments = async () => {
    try {
      const response = await fetch(`${getApiUrl()}/settings/hs-codes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) throw new Error("Failed to load documents");

      const result = await response.json();
      if (result.success) {
        setDocuments(result.documents);
      }
    } catch (error) {
      toast({
        title: "Failed to load documents",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (documentId: string) => {
    try {
      const [country, version] = documentId.split("-");
      const response = await fetch(`${getApiUrl()}/settings/hs-codes`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ country, version }),
      });

      if (!response.ok) throw new Error("Failed to delete document");

      toast({
        title: "Document deleted",
        description: "The document has been deleted successfully",
      });

      loadDocuments();
    } catch (error) {
      toast({
        title: "Delete failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-medium">Import HS Codes</h3>
        <p className="text-sm text-muted-foreground">
          Upload Excel files containing HS codes. Each file should have columns for
          'Chapter', 'Heading', 'HS Code', 'Export Statistical Item', 'UQ', and 'Description'.
        </p>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="country">Country Code</Label>
        <Select value={country} onValueChange={setCountry}>
          <SelectTrigger>
            <SelectValue placeholder="Select country" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="AU">Australia</SelectItem>
            <SelectItem value="US">United States</SelectItem>
            <SelectItem value="CN">China</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="version">HS Version</Label>
        <Select value={version} onValueChange={setVersion}>
          <SelectTrigger>
            <SelectValue placeholder="Select version" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="2022">2022</SelectItem>
            <SelectItem value="2017">2017</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="headerRow">Header Row Number</Label>
        <Input
          id="headerRow"
          type="number"
          value={headerRow}
          onChange={(e) => setHeaderRow(e.target.value)}
          min="1"
          className="w-full"
        />
        <p className="text-sm text-muted-foreground">
          Specify which row contains the column headers (default: 6)
        </p>
      </div>

      <div
        className={cn(
          "border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors",
          isDragging
            ? "border-primary bg-primary/10"
            : "border-muted-foreground/25 hover:border-primary/50"
        )}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById("file-upload")?.click()}
      >
        <input
          id="file-upload"
          type="file"
          className="hidden"
          onChange={handleFileSelect}
          accept=".xlsx,.xls,.csv"
          multiple
        />
        <div className="flex flex-col items-center gap-2">
          <Upload className="h-8 w-8 text-muted-foreground/50" />
          <p className="text-sm text-muted-foreground">
            Drop your HS code files here or click to browse
          </p>
          <p className="text-xs text-muted-foreground">Excel or CSV files only</p>
        </div>
      </div>

      {selectedFiles.length > 0 && (
        <div className="space-y-2">
          {selectedFiles.map((file, index) => (
            <div key={index} className="flex items-center justify-between text-sm p-2 border rounded">
              <div className="flex items-center gap-2">
                <FileText className="h-4 w-4" />
                <span>{file.name}</span>
              </div>
              <div className="flex items-center gap-2">
                {uploadProgress[file.name] !== undefined && (
                  <div className="w-20 bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-primary h-2 rounded-full transition-all"
                      style={{ width: `${uploadProgress[file.name]}%` }}
                    />
                  </div>
                )}
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={() => removeFile(index)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}

      <Button
        onClick={handleUpload}
        disabled={isUploading || selectedFiles.length === 0 || !country}
        className="w-full"
      >
        {isUploading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Importing Files...
          </>
        ) : (
          <>
            <Upload className="mr-2 h-4 w-4" />
            Upload HS Codes ({selectedFiles.length} file{selectedFiles.length !== 1 ? 's' : ''})
          </>
        )}
      </Button>

      <div className="mt-8">
        <h4 className="font-medium mb-2">Imported Documents</h4>
        <ScrollArea className="h-[200px] w-full rounded-md border p-4">
          {documents.length === 0 ? (
            <p className="text-sm text-muted-foreground text-center">
              No documents imported yet
            </p>
          ) : (
            <div className="space-y-2">
              {documents.map((doc) => (
                <div
                  key={doc.id}
                  className="flex items-center justify-between text-sm"
                >
                  <div className="flex items-center gap-2">
                    <FileText className="h-4 w-4" />
                    <span>{doc.name}</span>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8"
                    onClick={() => handleDelete(doc.id)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </ScrollArea>
      </div>
    </div>
  );
}
