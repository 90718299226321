import { Link, useLocation } from "react-router-dom";
import { cn } from "@/lib/utils";
import {
  FileSpreadsheet,
  Search,
  Ship,
  FileCheck,
  Users,
  MessageSquare,
  Settings,
  LogOut,
  LayoutDashboard,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/contexts/AuthContext";
import { hasPageAccess } from "@/lib/permissions";

const menuItems = [
  { icon: LayoutDashboard, label: "Dashboard", href: "/", permission: "dashboard" },
  { icon: FileSpreadsheet, label: "Convert to Excel", href: "/convert", permission: "convert" },
  { icon: Search, label: "HS Lookup", href: "/hs-lookup", permission: "hsLookup" },
  { icon: Ship, label: "FCL Search", href: "/fcl-search", permission: "fclSearch" },
  { icon: FileCheck, label: "Document Check", href: "/doc-check", permission: "docCheck" },
  { icon: MessageSquare, label: "Chat", href: "/chat", permission: "chat" },
  { icon: Users, label: "Users", href: "/users", permission: "users" },
  { icon: Settings, label: "Settings", href: "/settings", permission: "settings" },
] as const;

export default function Sidebar() {
  const location = useLocation();
  const { logout, user } = useAuth();

  return (
    <div className="w-64 h-screen bg-slate-50 border-r">
      <div className="p-6">
        <Link to="/" className="hover:no-underline">
          <h1 className="text-2xl font-bold">Freight Forward</h1>
        </Link>
      </div>

      <nav className="px-3 py-2 flex flex-col space-y-1">
        {menuItems.map((item) => {
          const Icon = item.icon;
          const isActive = location.pathname === item.href;
          
          // Skip menu items user doesn't have access to
          if (!hasPageAccess(user, item.permission)) {
            return null;
          }

          return (
            <Link key={item.href} to={item.href} className="hover:no-underline">
              <Button
                variant="ghost"
                className={cn(
                  "w-full justify-start gap-2",
                  isActive && "bg-slate-200"
                )}
              >
                <Icon className="h-4 w-4" />
                {item.label}
              </Button>
            </Link>
          );
        })}

        <Button
          variant="ghost"
          className="w-full justify-start gap-2 mt-4"
          onClick={logout}
        >
          <LogOut className="h-4 w-4" />
          Logout
        </Button>
      </nav>
    </div>
  );
}
