import { useState, useEffect } from "react";
import { checkAuthStatus } from "@/lib/auth";
import { useLocation } from "wouter";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  FileSpreadsheet,
  Search,
  Ship,
  FileCheck,
  Users,
  ArrowUpRight,
} from "lucide-react";
import { Link } from "wouter";

const stats = [
  {
    title: "Document Conversions",
    value: "24",
    icon: FileSpreadsheet,
    href: "/convert",
  },
  {
    title: "HS Lookups",
    value: "156",
    icon: Search,
    href: "/hs-lookup",
  },
  {
    title: "FCL Searches",
    value: "89",
    icon: Ship,
    href: "/fcl-search",
  },
  {
    title: "Doc Checks",
    value: "43",
    icon: FileCheck,
    href: "/doc-check",
  },
];

export default function Dashboard() {
  const [, navigate] = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const verifyAuth = async () => {
      const isAuth = await checkAuthStatus();
      setIsAuthenticated(isAuth);

      if (!isAuth) {
        navigate("/login");
      }
    };

    verifyAuth();
  }, [navigate]);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Dashboard</h1>
        <Card className="w-auto">
          <CardContent className="py-2 px-4">
            <div className="flex items-center gap-2">
              <Users size={16} />
              <span>5 Active Users</span>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {stats.map((stat) => {
          const Icon = stat.icon;
          return (
            <Link key={stat.href} href={stat.href}>
              <Card className="hover:bg-slate-50 cursor-pointer transition-colors">
                <CardHeader className="flex flex-row items-center justify-between pb-2">
                  <CardTitle className="text-sm font-medium">
                    {stat.title}
                  </CardTitle>
                  <Icon size={16} />
                </CardHeader>
                <CardContent>
                  <div className="flex justify-between items-center">
                    <div className="text-2xl font-bold">{stat.value}</div>
                    <ArrowUpRight size={20} className="text-green-500" />
                  </div>
                  <p className="text-xs text-muted-foreground">
                    +12% from last month
                  </p>
                </CardContent>
              </Card>
            </Link>
          );
        })}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Recent Conversions</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {[1, 2, 3].map((i) => (
                <div
                  key={i}
                  className="flex items-center justify-between py-2 border-b last:border-0"
                >
                  <div>
                    <p className="font-medium">document{i}.pdf</p>
                    <p className="text-sm text-muted-foreground">
                      Converted to Excel
                    </p>
                  </div>
                  <span className="text-sm text-muted-foreground">2h ago</span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Recent HS Lookups</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {[1, 2, 3].map((i) => (
                <Link
                  key={i}
                  href={`/hs-lookup?product=Product ${i}&code=8471.30.${i}0`}
                >
                  <div className="flex items-center justify-between py-2 border-b last:border-0 hover:bg-slate-50 cursor-pointer">
                    <div>
                      <p className="font-medium">Product {i}</p>
                      <p className="text-sm text-muted-foreground">
                        HS Code: 8471.30.{i}0
                      </p>
                    </div>
                    <span className="text-sm text-muted-foreground">
                      3h ago
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Recent FCL Searches</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {[
                {
                  query: "20ft container from Shanghai to Sydney",
                  timestamp: "2h ago",
                  results: "5 rates found",
                },
                {
                  query: "40ft container from Singapore to Melbourne",
                  timestamp: "4h ago",
                  results: "8 rates found",
                },
                {
                  query: "40HC from Hong Kong to Brisbane",
                  timestamp: "6h ago",
                  results: "3 rates found",
                },
              ].map((search, i) => (
                <Link
                  key={i}
                  href={`/fcl-search?query=${encodeURIComponent(search.query)}`}
                >
                  <div className="flex items-center justify-between py-2 border-b last:border-0 hover:bg-slate-50 cursor-pointer">
                    <div>
                      <p className="font-medium">{search.query}</p>
                      <p className="text-sm text-muted-foreground">
                        {search.results}
                      </p>
                    </div>
                    <span className="text-sm text-muted-foreground">
                      {search.timestamp}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
