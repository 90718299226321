import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuth, AuthProvider } from "./contexts/AuthContext";
import Login from "./pages/Login";
import LoadingSpinner from "./components/LoadingSpinner";
import MainLayout from "./components/layouts/MainLayout";
import Dashboard from "./pages/Dashboard";
import HSLookup from "./pages/HSLookup";
import FCLSearch from "./pages/FCLSearch";
import DocCheck from "./pages/DocCheck";
import Convert from "./pages/Convert";
import Settings from "./pages/Settings";
import Users from "./pages/Users";
import Chat from "./pages/Chat";
import { Toaster } from "./components/ui/toaster";
import RoleGuard from "./components/auth/RoleGuard";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = window.location;

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loading && !isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }

  return <>{children}</>;
};

const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to="/" replace /> : <>{children}</>;
};

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <RoleGuard page="dashboard">
                  <Dashboard />
                </RoleGuard>
              }
            />
            <Route
              path="convert"
              element={
                <RoleGuard page="convert">
                  <Convert />
                </RoleGuard>
              }
            />
            <Route
              path="hs-lookup"
              element={
                <RoleGuard page="hsLookup">
                  <HSLookup />
                </RoleGuard>
              }
            />
            <Route
              path="fcl-search"
              element={
                <RoleGuard page="fclSearch">
                  <FCLSearch />
                </RoleGuard>
              }
            />
            <Route
              path="doc-check"
              element={
                <RoleGuard page="docCheck">
                  <DocCheck />
                </RoleGuard>
              }
            />
            <Route
              path="chat"
              element={
                <RoleGuard page="chat">
                  <Chat />
                </RoleGuard>
              }
            />
            <Route
              path="users"
              element={
                <RoleGuard page="users">
                  <Users />
                </RoleGuard>
              }
            />
            <Route
              path="settings"
              element={
                <RoleGuard page="settings">
                  <Settings />
                </RoleGuard>
              }
            />
          </Route>
        </Routes>
        <Toaster />
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
