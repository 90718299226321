import { useState, useEffect, useMemo, useReducer } from "react";
import { useLocation } from "wouter";
import { checkAuthStatus } from "@/lib/auth";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { useFCLRates } from "@/lib/api";
import { getApiUrl } from "@/lib/config";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Ship, Save, Download, RefreshCcw } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

type SortableFields = "carrier" | "transit_time" | "cost" | "total_cost";

interface RateComparisonMetrics {
  averageCost: number;
  lowestCost: number;
  highestCost: number;
  averageTransitTime: number;
  carriersCount: number;
}

interface FCLRate {
  id: number;
  origin: string;
  destination: string;
  carrier: string;
  transit_time: string | null;
  cost: number;
  rebate: number | null;
  valid_until: string | null;
}

interface SavedSearch {
  id: string;
  name: string;
  query: string;
  results: FCLRate[];
  created_at: string;
}

interface RecentSearch {
  id: string;
  query: string;
  results: FCLRate[];
  created_at: string;
}

interface FCLRatesResponse {
  rates: FCLRate[];
}

interface SavedSearchResponse {
  searches: SavedSearch[];
}

interface RecentSearchResponse {
  searches: RecentSearch[];
}

function getSortValue(
  rate: FCLRate,
  field: SortableFields
): string | number | null {
  switch (field) {
    case "carrier":
      return rate.carrier;
    case "transit_time":
      return rate.transit_time;
    case "cost":
      return rate.cost;
    case "total_cost":
      return rate.cost - (rate.rebate || 0);
    default:
      return null;
  }
}

export default function FCLSearch() {
  const [, navigate] = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [params] = useLocation();
  const searchParams = new URLSearchParams(params);
  const initialQuery = searchParams.get("query") || "";
  const [query, setQuery] = useState(initialQuery);
  const [sortField, setSortField] = useState<SortableFields>("cost");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [selectedRates, setSelectedRates] = useState<Set<number>>(new Set());
  const { data: rates, isLoading, error, refetch } = useFCLRates();
  const { toast } = useToast();

  const [filterCriteria, setFilterCriteria] = useState({
    minCost: "",
    maxCost: "",
    carriers: [] as string[],
    transitTime: "",
    containerSize: "",
  });

  const [comparisonMetrics, setComparisonMetrics] =
    useState<RateComparisonMetrics>({
      averageCost: 0,
      lowestCost: 0,
      highestCost: 0,
      averageTransitTime: 0,
      carriersCount: 0,
    });

  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);
  const [saveSearchName, setSaveSearchName] = useState("");
  const [showSaveDialog, setShowSaveDialog] = useState(false);

  useEffect(() => {
    const verifyAuth = async () => {
      const isAuth = await checkAuthStatus();
      setIsAuthenticated(isAuth);

      if (!isAuth) {
        navigate("/login");
      }
    };

    verifyAuth();
  }, [navigate]);

  useEffect(() => {
    fetchSavedSearches();
    fetchRecentSearches();
  }, []);

  const fetchSavedSearches = async () => {
    try {
      // TODO: Replace with actual user ID from authentication
      const userId = localStorage.getItem("userId") || "current-user";
      const response = await fetch(
        getApiUrl(`carrier-rates/searches/saved/${userId}`)
      );
      if (!response.ok) throw new Error("Failed to fetch saved searches");
      const data = (await response.json()) as SavedSearchResponse;
      setSavedSearches(data.searches);
    } catch (error) {
      console.error("Error fetching saved searches:", error);
      toast({
        title: "Error",
        description: "Failed to fetch saved searches",
        variant: "destructive",
      });
    }
  };

  const fetchRecentSearches = async () => {
    try {
      const response = await fetch(
        getApiUrl("carrier-rates/searches/recent/current-user")
      );
      if (response.ok) {
        const data = (await response.json()) as RecentSearchResponse;
        setRecentSearches(data.searches);
      }
    } catch (error) {
      console.error("Error fetching recent searches:", error);
    }
  };

  const calculateMetrics = (rates: FCLRate[]) => {
    if (!rates?.length) return;

    const costs = rates.map((r) => r.cost - (r.rebate || 0));
    const transitTimes = rates
      .map((r) => (r.transit_time ? parseInt(r.transit_time) : 0))
      .filter((t) => t > 0);

    setComparisonMetrics({
      averageCost: costs.reduce((a, b) => a + b, 0) / costs.length,
      lowestCost: Math.min(...costs),
      highestCost: Math.max(...costs),
      averageTransitTime: transitTimes.length
        ? transitTimes.reduce((a, b) => a + b, 0) / transitTimes.length
        : 0,
      carriersCount: new Set(rates.map((r) => r.carrier)).size,
    });
  };

  useEffect(() => {
    if (rates?.rates) {
      calculateMetrics(rates.rates);
    }
  }, [rates]);

  const filteredAndSortedRates = useMemo(() => {
    if (!rates?.rates) return [];

    let filtered = [...rates.rates].filter((rate) => {
      const netCost = rate.cost - (rate.rebate || 0);

      if (
        filterCriteria.minCost &&
        netCost < parseFloat(filterCriteria.minCost)
      )
        return false;
      if (
        filterCriteria.maxCost &&
        netCost > parseFloat(filterCriteria.maxCost)
      )
        return false;

      if (filterCriteria.transitTime && rate.transit_time) {
        const transitDays = parseInt(rate.transit_time);
        if (transitDays > parseFloat(filterCriteria.transitTime)) return false;
      }

      return true;
    });

    return filtered.sort((a, b) => {
      const aValue = getSortValue(a, sortField);
      const bValue = getSortValue(b, sortField);

      if (aValue === null) return sortDirection === "asc" ? 1 : -1;
      if (bValue === null) return sortDirection === "asc" ? -1 : 1;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return sortDirection === "asc"
        ? (aValue as number) - (bValue as number)
        : (bValue as number) - (aValue as number);
    });
  }, [rates, sortField, sortDirection, filterCriteria]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!query.trim()) return;

    try {
      await refetch();

      // Add to recent searches
      const recentSearches = JSON.parse(
        localStorage.getItem("recentSearches") || "[]"
      );
      const newSearch = {
        id: Date.now(),
        query,
        timestamp: new Date().toISOString(),
      };
      localStorage.setItem(
        "recentSearches",
        JSON.stringify([newSearch, ...recentSearches].slice(0, 5))
      );
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch rates",
        variant: "destructive",
      });
    }
  };

  const handleSaveSearch = async () => {
    if (!saveSearchName) {
      toast({
        title: "Error",
        description: "Please enter a name for the saved search",
        variant: "destructive",
      });
      return;
    }

    try {
      const response = await fetch(getApiUrl("carrier-rates/searches/save"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: saveSearchName,
          query,
          results: rates,
          userId: "current-user",
        }),
      });

      if (response.ok) {
        toast({
          title: "Success",
          description: "Search saved successfully",
        });
        setShowSaveDialog(false);
        setSaveSearchName("");
        fetchSavedSearches();
      }
    } catch (error) {
      console.error("Error saving search:", error);
      toast({
        title: "Error",
        description: "Failed to save search",
        variant: "destructive",
      });
    }
  };

  const handleSearchAgain = async (searchQuery: string) => {
    setQuery(searchQuery);
    await refetch();
  };

  const handleSaveReport = () => {
    if (!rates?.rates?.length) {
      toast({
        title: "Error",
        description: "No rates to save",
        variant: "destructive",
      });
      return;
    }
    setShowSaveDialog(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">FCL Rate Search</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-1">
          <Card>
            <CardHeader>
              <CardTitle>Search Rates</CardTitle>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <Textarea
                    placeholder="Enter your rate query..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="min-h-[200px]"
                  />
                  <p className="text-sm text-muted-foreground mt-2">
                    Example: "20ft container from Shanghai to Sydney in June"
                  </p>
                </div>

                <Button type="submit" className="w-full">
                  <Ship className="mr-2 h-4 w-4" />
                  Get Rates
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>

        <div className="lg:col-span-2">
          <Card>
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardTitle>Rate Comparison</CardTitle>
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={handleSaveReport}
                  >
                    <Save className="mr-2 h-4 w-4" />
                    Save Report
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      if (!filteredAndSortedRates?.length) return;

                      const headers = [
                        "Carrier",
                        "Route",
                        "Transit Time",
                        "Base Rate",
                        "Rebate",
                        "Net Rate",
                      ];
                      const csvContent = [
                        headers.join(","),
                        ...filteredAndSortedRates.map((rate) =>
                          [
                            rate.carrier,
                            `${rate.origin} �� ${rate.destination}`,
                            rate.transit_time || "",
                            rate.cost,
                            rate.rebate || 0,
                            rate.cost - (rate.rebate || 0),
                          ].join(",")
                        ),
                      ].join("\n");

                      const blob = new Blob([csvContent], {
                        type: "text/csv;charset=utf-8;",
                      });
                      const url = URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = url;
                      link.download = `fcl-rates-${new Date().toISOString()}.csv`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    <Download className="mr-2 h-4 w-4" />
                    Export
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                <div className="p-4 bg-slate-50 rounded-lg">
                  <h3 className="text-sm font-medium text-muted-foreground mb-2">
                    Cost Analysis
                  </h3>
                  <div className="space-y-1">
                    <div className="flex justify-between">
                      <span>Average</span>
                      <span className="font-medium">
                        ${comparisonMetrics.averageCost.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between text-green-600">
                      <span>Lowest</span>
                      <span className="font-medium">
                        ${comparisonMetrics.lowestCost.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between text-red-600">
                      <span>Highest</span>
                      <span className="font-medium">
                        ${comparisonMetrics.highestCost.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="p-4 bg-slate-50 rounded-lg">
                  <h3 className="text-sm font-medium text-muted-foreground mb-2">
                    Transit Time
                  </h3>
                  <div className="space-y-1">
                    <div className="flex justify-between">
                      <span>Average</span>
                      <span className="font-medium">
                        {comparisonMetrics.averageTransitTime.toFixed(1)} days
                      </span>
                    </div>
                  </div>
                </div>

                <div className="p-4 bg-slate-50 rounded-lg">
                  <h3 className="text-sm font-medium text-muted-foreground mb-2">
                    Carriers
                  </h3>
                  <div className="space-y-1">
                    <div className="flex justify-between">
                      <span>Total</span>
                      <span className="font-medium">
                        {comparisonMetrics.carriersCount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-6 space-y-2">
                <h3 className="text-sm font-medium">Filters</h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <div>
                    <Input
                      type="number"
                      placeholder="Min Cost"
                      value={filterCriteria.minCost}
                      onChange={(e) =>
                        setFilterCriteria((prev) => ({
                          ...prev,
                          minCost: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <Input
                      type="number"
                      placeholder="Max Cost"
                      value={filterCriteria.maxCost}
                      onChange={(e) =>
                        setFilterCriteria((prev) => ({
                          ...prev,
                          maxCost: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <Input
                      type="number"
                      placeholder="Max Transit Time (days)"
                      value={filterCriteria.transitTime}
                      onChange={(e) =>
                        setFilterCriteria((prev) => ({
                          ...prev,
                          transitTime: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <Select
                      value={filterCriteria.containerSize}
                      onValueChange={(value) =>
                        setFilterCriteria((prev) => ({
                          ...prev,
                          containerSize: value,
                        }))
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Container Size" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="20">20ft</SelectItem>
                        <SelectItem value="40">40ft</SelectItem>
                        <SelectItem value="40HC">40ft HC</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>

              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[50px]">
                      <Checkbox
                        checked={rates?.rates?.length === selectedRates.size}
                        onCheckedChange={(checked) => {
                          if (checked && rates?.rates) {
                            setSelectedRates(
                              new Set(rates.rates.map((r) => r.id))
                            );
                          } else {
                            setSelectedRates(new Set());
                          }
                        }}
                      />
                    </TableHead>
                    <TableHead>
                      <button
                        onClick={() => {
                          if (sortField === "carrier") {
                            setSortDirection((d) =>
                              d === "asc" ? "desc" : "asc"
                            );
                          } else {
                            setSortField("carrier");
                            setSortDirection("asc");
                          }
                        }}
                        className="flex items-center hover:text-primary"
                      >
                        Carrier
                        {sortField === "carrier" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </button>
                    </TableHead>
                    <TableHead>Route</TableHead>
                    <TableHead>
                      <button
                        onClick={() => {
                          if (sortField === "transit_time") {
                            setSortDirection((d) =>
                              d === "asc" ? "desc" : "asc"
                            );
                          } else {
                            setSortField("transit_time");
                            setSortDirection("asc");
                          }
                        }}
                        className="flex items-center hover:text-primary"
                      >
                        Transit Time
                        {sortField === "transit_time" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </button>
                    </TableHead>
                    <TableHead>
                      <button
                        onClick={() => {
                          if (sortField === "cost") {
                            setSortDirection((d) =>
                              d === "asc" ? "desc" : "asc"
                            );
                          } else {
                            setSortField("cost");
                            setSortDirection("asc");
                          }
                        }}
                        className="flex items-center hover:text-primary"
                      >
                        Base Rate
                        {sortField === "cost" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </button>
                    </TableHead>
                    <TableHead>Rebate</TableHead>
                    <TableHead>
                      <button
                        onClick={() => {
                          if (sortField === "total_cost") {
                            setSortDirection((d) =>
                              d === "asc" ? "desc" : "asc"
                            );
                          } else {
                            setSortField("total_cost");
                            setSortDirection("asc");
                          }
                        }}
                        className="flex items-center hover:text-primary"
                      >
                        Net Rate
                        {sortField === "total_cost" &&
                          (sortDirection === "asc" ? "↑" : "↓")}
                      </button>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={7} className="text-center">
                        Loading rates...
                      </TableCell>
                    </TableRow>
                  ) : error ? (
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        className="text-center text-red-500"
                      >
                        Failed to load rates
                      </TableCell>
                    </TableRow>
                  ) : !filteredAndSortedRates?.length ? (
                    <TableRow>
                      <TableCell colSpan={7} className="text-center">
                        No rates found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredAndSortedRates.map((rate) => (
                      <TableRow
                        key={rate.id}
                        className={
                          selectedRates.has(rate.id) ? "bg-primary/5" : ""
                        }
                      >
                        <TableCell>
                          <Checkbox
                            checked={selectedRates.has(rate.id)}
                            onCheckedChange={(checked) => {
                              const newSelected = new Set(selectedRates);
                              if (checked) {
                                newSelected.add(rate.id);
                              } else {
                                newSelected.delete(rate.id);
                              }
                              setSelectedRates(newSelected);
                            }}
                          />
                        </TableCell>
                        <TableCell>{rate.carrier}</TableCell>
                        <TableCell>
                          {rate.origin} → {rate.destination}
                        </TableCell>
                        <TableCell>{rate.transit_time || "-"}</TableCell>
                        <TableCell>${rate.cost}</TableCell>
                        <TableCell>${rate.rebate || 0}</TableCell>
                        <TableCell>${rate.cost - (rate.rebate || 0)}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        <Card>
          <CardHeader>
            <CardTitle>Recent Searches</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[300px]">
              <div className="space-y-2">
                {JSON.parse(localStorage.getItem("recentSearches") || "[]").map(
                  (search: any) => (
                    <div
                      key={search.id}
                      className="p-2 hover:bg-accent rounded-lg cursor-pointer"
                      onClick={() => handleSearchAgain(search.query)}
                    >
                      <p className="font-medium">{search.query}</p>
                      <p className="text-sm text-gray-500">
                        {new Date(search.timestamp).toLocaleDateString()}
                      </p>
                    </div>
                  )
                )}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Saved Searches</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[300px]">
              <div className="space-y-2">
                {savedSearches.map((search) => (
                  <div
                    key={search.id}
                    className="p-2 hover:bg-accent rounded-lg cursor-pointer"
                    onClick={() => handleSearchAgain(search.query)}
                  >
                    <p className="font-medium">{search.name}</p>
                    <p className="text-sm text-gray-500">
                      {new Date(search.created_at).toLocaleDateString()}
                    </p>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>

      {rates && rates.length > 0 && (
        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={handleSaveReport}>
            <Save className="h-4 w-4 mr-2" />
            Save Report
          </Button>
        </div>
      )}

      <Dialog open={showSaveDialog} onOpenChange={setShowSaveDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save Rate Comparison</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <label>Report Name</label>
              <Input
                value={saveSearchName}
                onChange={(e) => setSaveSearchName(e.target.value)}
                placeholder="Enter a name for this report"
              />
            </div>
            <div className="flex justify-end gap-2">
              <Button
                variant="outline"
                onClick={() => setShowSaveDialog(false)}
              >
                Cancel
              </Button>
              <Button onClick={handleSaveSearch}>Save</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
