import { User } from '../types/auth';

export const PERMISSIONS = {
  CONVERT_TO_EXCEL: 'convert_to_excel',
  HS_LOOKUP: 'hs_lookup',
  FCL_SEARCH: 'fcl_search',
  DOC_CHECK: 'doc_check',
  CHAT: 'chat',
  MANAGE_USERS: 'manage_users',
  MANAGE_SETTINGS: 'manage_settings',
} as const;

export type Permission = typeof PERMISSIONS[keyof typeof PERMISSIONS];

// Define page access permissions
export const PAGE_PERMISSIONS = {
  dashboard: [],  // Everyone can access dashboard
  convert: [PERMISSIONS.CONVERT_TO_EXCEL],
  hsLookup: [PERMISSIONS.HS_LOOKUP],
  fclSearch: [PERMISSIONS.FCL_SEARCH],
  docCheck: [PERMISSIONS.DOC_CHECK],
  chat: [PERMISSIONS.CHAT],
  users: [PERMISSIONS.MANAGE_USERS],
  settings: [PERMISSIONS.MANAGE_SETTINGS],
} as const;

// Default permissions for each role
export const ROLE_PERMISSIONS: Record<string, Permission[]> = {
  admin: Object.values(PERMISSIONS),
  user: [
    PERMISSIONS.CONVERT_TO_EXCEL,
    PERMISSIONS.HS_LOOKUP,
    PERMISSIONS.FCL_SEARCH,
    PERMISSIONS.DOC_CHECK,
    PERMISSIONS.CHAT,
  ],
};

// Check if user has permission to access a page
export function hasPageAccess(user: User | null, page: keyof typeof PAGE_PERMISSIONS): boolean {
  if (!user) return false;
  
  // Admins have access to everything
  if (user.role === 'admin') return true;
  
  // For dashboard or pages without specific permissions
  if (PAGE_PERMISSIONS[page].length === 0) return true;
  
  // Check if user has any of the required permissions
  const userPermissions = getUserPermissions(user);
  return PAGE_PERMISSIONS[page].some(permission => userPermissions.includes(permission));
}

// Get user's permissions based on role and custom permissions
export function getUserPermissions(user: User): Permission[] {
  if (!user) return [];
  
  // Start with role-based permissions
  const rolePermissions = ROLE_PERMISSIONS[user.role] || [];
  
  // Add custom permissions if any
  const customPermissions = user.permissions ? JSON.parse(user.permissions) : [];
  
  // Combine and deduplicate permissions
  return [...new Set([...rolePermissions, ...customPermissions])];
}

// Format permissions for display
export function formatPermission(permission: Permission): string {
  return permission
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}
