import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

export default function MainLayout() {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <main className="flex-1 overflow-y-auto bg-white">
        <div className="container mx-auto py-6">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
