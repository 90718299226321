import { useState, useCallback } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Upload, FileText, Trash2 } from "lucide-react";
import { cn } from "@/lib/utils";

interface Template {
  id: number;
  name: string;
  type: string;
  content: string;
  created_at: string;
}

export default function DocumentCheckSettings() {
  const { toast } = useToast();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
      const file = e.dataTransfer.files[0];
      if (file && file.type === "application/pdf") {
        setSelectedFile(file);
      } else {
        toast({
          title: "Invalid file type",
          description: "Please upload a PDF file",
          variant: "destructive",
        });
      }
    },
    [toast]
  );

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file && file.type === "application/pdf") {
        setSelectedFile(file);
      } else {
        toast({
          title: "Invalid file type",
          description: "Please upload a PDF file",
          variant: "destructive",
        });
      }
    },
    [toast]
  );

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("type", "document_check");

    try {
      const response = await fetch("/api/templates/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("Failed to upload template");

      toast({
        title: "Success",
        description: "Template uploaded successfully",
      });

      setSelectedFile(null);
      setUploadProgress(0);
      // Refresh templates list
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to upload template",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (templateId: number) => {
    try {
      const response = await fetch(`/api/templates/${templateId}`, {
        method: "DELETE",
      });

      if (!response.ok) throw new Error("Failed to delete template");

      toast({
        title: "Success",
        description: "Template deleted successfully",
      });

      // Refresh templates list
      setTemplates(templates.filter((template) => template.id !== templateId));
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to delete template",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <FileText className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-medium">Document Check Templates</h3>
        </div>

        <div
          className={cn(
            "border-2 border-dashed rounded-lg p-6 text-center cursor-pointer",
            isDragging ? "border-primary bg-primary/10" : "border-gray-300",
            selectedFile && "border-green-500 bg-green-50"
          )}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.getElementById("template-upload")?.click()}
        >
          <input
            id="template-upload"
            type="file"
            className="hidden"
            accept=".pdf"
            onChange={handleFileSelect}
          />
          <div className="flex flex-col items-center gap-2">
            <Upload
              className={cn(
                "w-10 h-10",
                selectedFile ? "text-green-500" : "text-gray-400"
              )}
            />
            <div className="text-sm text-gray-600">
              {selectedFile ? (
                <>
                  <p className="font-medium text-green-600">
                    {selectedFile.name}
                  </p>
                  <p className="text-sm text-gray-500">
                    {Math.round(selectedFile.size / 1024)} KB
                  </p>
                </>
              ) : (
                <>
                  <p className="font-medium">
                    Drop your template here or click to browse
                  </p>
                  <p className="text-xs text-gray-500">PDF files only</p>
                </>
              )}
            </div>
          </div>
        </div>

        {selectedFile && (
          <div className="mt-4 flex justify-end">
            <Button onClick={handleUpload}>Upload Template</Button>
          </div>
        )}

        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="mt-4">
            <div className="h-2 bg-gray-200 rounded-full">
              <div
                className="h-full bg-blue-500 rounded-full transition-all"
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
          </div>
        )}

        <ScrollArea className="h-[300px] mt-6">
          <div className="space-y-4">
            {templates.map((template) => (
              <div
                key={template.id}
                className="flex items-center justify-between p-3 border rounded-lg"
              >
                <div className="flex items-center gap-2">
                  <FileText className="w-5 h-5 text-blue-500" />
                  <div>
                    <p className="text-sm font-medium">{template.name}</p>
                    <p className="text-xs text-gray-500">
                      Added on{" "}
                      {new Date(template.created_at).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => handleDelete(template.id)}
                  >
                    <Trash2 className="w-4 h-4 text-red-500" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </Card>

      <Card className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <FileText className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-medium">Template Settings</h3>
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <Label>Minimum Match Score</Label>
            <Input
              type="number"
              min="0"
              max="100"
              defaultValue="80"
              className="w-full"
            />
            <p className="text-sm text-gray-500">
              Set the minimum similarity score required for a document to match
              a template
            </p>
          </div>

          <div className="space-y-2">
            <Label>Auto-Delete Unmatched Documents</Label>
            <div className="flex items-center gap-2">
              <Input type="number" min="1" defaultValue="7" className="w-32" />
              <span className="text-sm text-gray-500">days</span>
            </div>
            <p className="text-sm text-gray-500">
              Documents that don't match any template will be automatically
              deleted after this period
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}
