import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { FileSpreadsheet, Upload, Trash2, Download } from "lucide-react";
import { cn } from "@/lib/utils";
import { useTemplates, useUploadTemplate, useDeleteTemplate } from "@/lib/api";

interface Template {
  id: number;
  name: string;
  filename: string;
  created_at: string;
}

export default function ConvertSettings() {
  const { toast } = useToast();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [templateName, setTemplateName] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const { data: templatesData, isLoading } = useTemplates();
  const uploadTemplateMutation = useUploadTemplate();
  const deleteTemplateMutation = useDeleteTemplate();

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      validateAndSetFile(file);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      validateAndSetFile(file);
    }
  };

  const validateAndSetFile = (file: File) => {
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    if (!allowedTypes.includes(file.type)) {
      toast({
        title: "Invalid file type",
        description: "Please upload an Excel or CSV file",
        variant: "destructive",
      });
      return;
    }
    setSelectedFile(file);
  };

  const handleUploadTemplate = async () => {
    if (!selectedFile || !templateName.trim()) {
      toast({
        title: "Missing required fields",
        description: "Please provide a template name and select a file",
        variant: "destructive",
      });
      return;
    }

    try {
      await uploadTemplateMutation.mutateAsync({
        file: selectedFile,
        name: templateName,
      });

      toast({
        title: "Template uploaded",
        description: "Your template has been uploaded successfully",
      });

      setSelectedFile(null);
      setTemplateName("");
    } catch (error) {
      toast({
        title: "Upload failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    }
  };

  const handleDeleteTemplate = async (templateId: number) => {
    try {
      await deleteTemplateMutation.mutateAsync(templateId);

      toast({
        title: "Template deleted",
        description: "The template has been deleted successfully",
      });
    } catch (error) {
      toast({
        title: "Delete failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        {/* Template Upload */}
        <div className="space-y-2">
          <Label>Template Name</Label>
          <Input
            placeholder="Enter template name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </div>

        <div
          className={cn(
            "border-2 border-dashed rounded-lg p-6 text-center cursor-pointer",
            isDragging ? "border-primary bg-primary/10" : "border-gray-300",
            selectedFile && "border-green-500 bg-green-50"
          )}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.getElementById("template-upload")?.click()}
        >
          <input
            id="template-upload"
            type="file"
            className="hidden"
            accept=".xlsx,.xls,.csv"
            onChange={handleFileSelect}
          />
          <div className="flex flex-col items-center gap-2">
            <Upload
              className={cn(
                "w-10 h-10",
                selectedFile ? "text-green-500" : "text-gray-400"
              )}
            />
            {selectedFile ? (
              <div>
                <p className="text-sm font-medium">{selectedFile.name}</p>
                <p className="text-xs text-gray-500">
                  {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                </p>
              </div>
            ) : (
              <div>
                <p className="text-sm font-medium">
                  Drop your template here or click to browse
                </p>
                <p className="text-xs text-gray-500">Excel or CSV files only</p>
              </div>
            )}
          </div>
        </div>

        <Button
          onClick={handleUploadTemplate}
          disabled={
            !selectedFile ||
            !templateName.trim() ||
            uploadTemplateMutation.isPending
          }
        >
          {uploadTemplateMutation.isPending
            ? "Uploading..."
            : "Upload Template"}
        </Button>
      </div>

      {/* Template List */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium">Existing Templates</h3>
        <ScrollArea className="h-[200px] w-full rounded-md border p-2">
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-sm text-gray-500">Loading templates...</p>
            </div>
          ) : templatesData?.templates?.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-sm text-gray-500">No templates found</p>
            </div>
          ) : (
            <div className="space-y-2">
              {templatesData?.templates?.map((template: Template) => (
                <div
                  key={template.id}
                  className="flex items-center justify-between p-2 rounded-lg border"
                >
                  <div className="flex items-center gap-2">
                    <FileSpreadsheet className="w-5 h-5 text-blue-500" />
                    <div>
                      <p className="text-sm font-medium">{template.name}</p>
                      <p className="text-xs text-gray-500">
                        {template.filename}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => handleDeleteTemplate(template.id)}
                      disabled={deleteTemplateMutation.isPending}
                    >
                      <Trash2 className="w-4 h-4 text-red-500" />
                    </Button>
                    <Button size="sm" variant="ghost" asChild>
                      <a
                        href={`/api/templates/${template.id}/download`}
                        download
                      >
                        <Download className="w-4 h-4" />
                      </a>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </ScrollArea>
      </div>
    </div>
  );
}
